<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <fieldset class="p-2 mt-3 border">
                    <legend class="w-auto">RSU</legend>
                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th>Art-Nr.</th>
                                <th>Bezeichnung</th>
                                <th>Exportieren?</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template>
                                <tr v-for="(variation, index) in article.variations" :key="variation.id">
                                    <td>{{ variation.ordernumber }}</td>
                                    <td>{{ variation.article.name }}</td>
                                    <td>
                                        <div class="form-group">
                                            <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                                <input type="checkbox" class="custom-control-input" :id="'customSwitch' + index" v-model="variation.exportRSU">
                                                <label class="custom-control-label" :for="'customSwitch' + index"></label>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table> 
                    <div class="text-right">
                        <button class="btn btn-primary btn-small" @click="saveRSU()">
                            <i class="fas fa-save"></i> Speichern
                        </button>
                    </div>                        
                </fieldset>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ArticleMarketplace",

    props: [
        'article',
    ],

    data() {
        return {
            rsu: [],
        }
    },

    methods:{
        saveRSU()
        {
            var list = [];
            this.article.variations.forEach(element => {
                list.push({
                    article_detail_id: element.id,
                    exportRSU: element.exportRSU,
                });
            });


            this.axios
                .post("articles/setRSU", {
                    rsu: list
                })
                .then(() => {
                    // this.$parent.loadArticle();
                    this.$emit('articleUpdate');
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    })
                })
        }
    },

    updated(){

    }
}
</script>

<style>

</style>