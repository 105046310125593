<template>
    <b-container fluid>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-outline card-primary">
                    <div class="card-header">
                        <div class="card-title">
                            <h4><i class="fa fa-briefcase"></i> Artikelverwaltung</h4>
                            <p>Artikel editieren</p>
                        </div>
                    </div>

                    <div class="card-body">
                        <b-card no-body header-bg-variant="primary" header-border-variant="primary">
                            <b-tabs card>
                                <b-tab title="Allgemein" active>
                                    <fieldset class="p-2 border">
                                        <legend class="w-auto">Stammdaten</legend>
                                        <div class="mb-3 row">
                                            <div class="text-right col-md-12">
                                                <router-link class="mr-1 btn btn-success btn-sm" :to="{name: 'products.details', params: {id: article.product.id}}" v-if="$auth.check('products.show') && article.product != null"><i class="fas fa-box"></i> Produktstammdatenblatt</router-link>
                                                <button type="button" class="btn btn-warning btn-sm mr-1" @click="cloneArticle">
                                                    <i class="fas fa-fw fa-clone"></i> Duplizieren
                                                </button>
                                                <button type="button" class="btn btn-primary btn-sm" @click="saveArticle">
                                                    <i class="fas fa-fw fa-save"></i> Speichern
                                                </button>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">

                                                <div class="form-group row">
                                                    <label for="name" class="control-label col-sm-3 col-form-label col-form-label-sm">Artikelname*</label>
                                                    <div class="col-sm-8">
                                                        <input type="text" class="form-control form-control-sm" name="name" id="name" :class="{'is-invalid': form.errors.has('name')}" v-model="form.name">
                                                        <has-error :form="form" field="name"></has-error>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label for="ordernumber" class="control-label col-sm-3 col-form-label col-form-label-sm">Artikelnummer*</label>
                                                    <div class="col-sm-8">
                                                        <input type="text" class="form-control form-control-sm" name="ordernumber" id="ordernumber" :class="{'is-invalid': form.errors.has('ordernumber')}" v-model="form.ordernumber">
                                                        <has-error :form="form" field="ordernumber"></has-error>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label for="" class="control-label col-sm-3 col-form-label col-form-label-sm">Warengruppe*</label>
                                                    <div class="col-sm-8">
                                                        <Select2 v-model="form.groups" :options="groups" :settings="{ multiple: true }" :class="{'is-invalid': form.errors.has('groups')}" />
                                                        <has-error :form="form" field="groups"></has-error>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label for="seo_category" class="control-label col-sm-3 col-form-label col-form-label-sm">SEO-Kategorie*</label>
                                                    <div class="col-sm-8">
                                                        <Select2 v-model="form.seo_category" :options="seoCategories" :class="{'is-invalid': form.errors.has('seo_category')}" />
                                                        <has-error :form="form" field="seo_category"></has-error>
                                                    </div>
                                                </div>

                                                <!-- <div class="form-group row">
                                                    <label for="active" class="control-label col-sm-3 col-form-label col-form-label-sm">Aktiv</label>
                                                    <div class="col-sm-8">
                                                        <input type="checkbox" class="form-check-input" name="active" id="active" :class="{'is-invalid': form.errors.has('active')}" v-model="form.active" style="position: static; margin-left: 0;">
                                                        <has-error :form="form" field="active"></has-error>
                                                    </div>
                                                </div> -->

                                                <div class="form-group row">
                                                    <label for="active" class="control-label col-sm-3 col-form-label col-form-label-sm">Aktiv</label>
                                                    <div class="col-sm-8 custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                                        <input type="checkbox" class="custom-control-input" id="customSwitchActive" :class="{'is-invalid': form.errors.has('active')}" v-model="form.active">
                                                        <label class="custom-control-label" for="customSwitchActive"></label>
                                                        <has-error :form="form" field="active"></has-error>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label for="hasVariations" class="control-label col-sm-3 col-form-label col-form-label-sm">Varianten</label>
                                                    <div class="col-sm-8">
                                                        <input type="checkbox" @change="toggleHasVariation" :disabled="form.bundle == 1" value="1" class="form-check-input" name="hasVariations" id="hasVariations" :class="{'is-invalid': form.errors.has('hasVariations')}" v-model="form.hasVariations" style="position: static; margin-left: 0;">
                                                        <has-error :form="form" field="hasVariations"></has-error>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label for="bundle" class="control-label col-sm-3 col-form-label col-form-label-sm">Stückliste</label>
                                                    <div class="col-sm-8">
                                                        <input type="checkbox" value="1" class="form-check-input" :disabled="form.hasVariations == 1" name="bundle" id="bundle" :class="{'is-invalid': form.errors.has('bundle')}" v-model="form.bundle" style="position: static; margin-left: 0;">
                                                        <has-error :form="form" field="bundle"></has-error>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label for="greetingcard" class="control-label col-sm-3 col-form-label col-form-label-sm">Grußkarte</label>
                                                    <div class="col-sm-8">
                                                        <input type="checkbox" value="1" class="form-check-input" :disabled="form.hasVariations == 1 || article.voucher == 1" name="greetingcard" id="greetingcard" :class="{'is-invalid': form.errors.has('greetingcard')}" v-model="form.greetingcard" style="position: static; margin-left: 0;">
                                                        <has-error :form="form" field="greetingcard"></has-error>
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="col-md-6">

                                                <div class="form-group row">
                                                    <label for="producer_id" class="control-label col-sm-3 offset-sm-1 col-form-label col-form-label-sm">Hersteller Front*</label>
                                                    <div class="col-sm-8">
                                                        <select name="producer_id" id="producer_id" class="form-control form-control-sm" v-model="form.producer_id" :class="{'is-invalid': form.errors.has('producer_id')}">
                                                            <option v-for="producer in producers" :key="producer.id" v-bind:value="producer.id">{{ producer.number }} &ndash; {{ producer.name  }}</option>
                                                        </select>
                                                        <has-error :form="form" field="producer_id"></has-error>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label for="distributor_id" class="control-label col-sm-3 offset-sm-1 col-form-label col-form-label-sm">Lieferant (Bestellung intern)*</label>
                                                    <div class="col-sm-8">
                                                        <select name="distributor_id_id" id="distributor_id_id" class="form-control form-control-sm" v-model="form.distributor_id" :class="{'is-invalid': form.errors.has('distributor_id')}">
                                                            <option v-for="distributor in distributors" :key="distributor.id" v-bind:value="distributor.id">{{ distributor.number }} &ndash; {{ distributor.name  }}</option>
                                                        </select>
                                                        <has-error :form="form" field="distributor_id"></has-error>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label for="tax_id" class="control-label col-sm-3 offset-sm-1 col-form-label col-form-label-sm">MwSt*</label>
                                                    <div class="col-sm-8">
                                                        <div v-for="tax in taxes" :key="tax.id" class="form-check-inline form-check">
                                                            <input v-model="form.tax_id" :value="tax.id" type="radio" name="tax_id" id="tax_id" class="form-check-input" style="position: static; margin-left: 0;">
                                                            <label  class="control-label form-check-label">{{tax.description}}</label>
                                                        <has-error :form="form" field="tax_id"></has-error>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label for="han" class="control-label col-sm-3 offset-sm-1 col-form-label col-form-label-sm">Lieferantenartikelnr.</label>
                                                    <div class="col-sm-8">
                                                        <input type="text" class="form-control form-control-sm" name="han" id="han" :class="{'is-invalid': form.errors.has('han')}" v-model="form.han">
                                                        <has-error :form="form" field="han"></has-error>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label for="ean" class="control-label col-sm-3 offset-sm-1 col-form-label col-form-label-sm">EAN</label>
                                                    <div class="col-sm-8">
                                                        <input type="text" class="form-control form-control-sm" name="ean" id="ean" :class="{'is-invalid': form.errors.has('ean')}" v-model="form.ean">
                                                        <has-error :form="form" field="ean"></has-error>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>

                                    <div class="row">
                                        <div class="col-md-4">
                                            <fieldset class="p-2 mt-3 border" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                                                <legend class="w-auto">Regalzuweisung</legend>
                                                <button class="btn btn-primary btn-xs" type="button" @click="addShelf"><i class="fas fa-fw fa-box"></i>Neues Regal</button>
                                                <template v-if="form.shelves.length == 0">
                                                    <h5>Derzeit sind diesem Artikel keine Lagerplätze zugewiesen.</h5>
                                                </template>

                                                <template v-if="form.shelves.length >= 1">
                                                    <table class="table table-striped table-sm">
                                                        <thead>
                                                            <th>Regal</th>
                                                            <th>Ebene</th>
                                                            <th>Aktionen</th>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(shelf, index) in form.shelves" :key="shelf.id">
                                                                <td>
                                                                    <!-- <input type="text" class="form-control form-control-sm" v-model="form.shelves[index].shelf" /> -->
                                                                    <select class="form-control form-control-sm" v-model="form.shelves[index].shelf_id" @change="shelfChange($event, index)">
                                                                        <option v-for="shelve in shelves" :key="shelve.id" :value="shelve.id">{{ shelve.name }}</option>
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <!-- <input type="text" class="form-control form-control-sm" v-model="form.shelves[index].layer" /> -->
                                                                    <select class="form-control form-control-sm" v-model="form.shelves[index].layer">
                                                                        <option v-for="layer in form.shelves[index].layers" :key="layer" :value="layer">Ebene {{ layer }}</option>
                                                                        <!-- <option value="2">Ebene 2</option>
                                                                        <option value="3">Ebene 3</option> -->
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <button type="button" class="btn btn-danger btn-xs" @click="deleteShelf(index)"><i class="fas fa-fw fa-trash"></i></button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </template>
                                            </fieldset>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4" style="display: flex; flex-direction: column">
                                            <fieldset class="p-2 mt-3 border" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                                                <legend class="w-auto">Artikeleigenschaften</legend>
                                                
                                                <div class="form-group row" style="color: #499360;">
                                                    <label for="demeter" class="control-label col-sm-3 col-form-label col-form-label-sm">Demeter</label>
                                                    <div class="col-sm-8">
                                                        <input type="checkbox" class="form-check-input" name="demeter" id="demeter" :class="{'is-invalid': form.errors.has('demeter')}" v-model="form.demeter" style="position: static; margin-left: 0;">
                                                        <has-error :form="form" field="demeter"></has-error>
                                                    </div>
                                                </div>
                                                
                                                <div class="form-group row" style="color: #499360;">
                                                    <label for="naturland" class="control-label col-sm-3 col-form-label col-form-label-sm">Naturland</label>
                                                    <div class="col-sm-8">
                                                        <input type="checkbox" class="form-check-input" name="naturland" id="naturland" :class="{'is-invalid': form.errors.has('naturland')}" v-model="form.naturland" style="position: static; margin-left: 0;">
                                                        <has-error :form="form" field="naturland"></has-error>
                                                    </div>
                                                </div>

                                                <div class="form-group input-group-sm row">
                                                    <label for="bio" class="control-label col-sm-3 col-form-label col-form-label-sm">Bio</label>
                                                    <div class="col-sm-8">
                                                        <input type="checkbox" class="form-check-input" name="bio" id="bio" :class="{'is-invalid': form.errors.has('bio')}" v-model="form.bio" style="position: static; margin-left: 0;">
                                                        <has-error :form="form" field="bio"></has-error>
                                                    </div>
                                                    <template v-if="form.bio == 1">
                                                        <label for="bio" class="control-label col-sm-4 col-form-label col-form-label-sm">Kontrollnummer: </label>
                                                        <div class="col-sm-7" >
                                                            <input type="text" class="form-control form-control-sm" v-model="form.bio_controllnumber" :class="{'is-invalid': form.errors.has('bio_controllnumber')}"/>
                                                            <has-error :form="form" field="bio_controllnumber"></has-error>
                                                        </div>
                                                    </template>

                                                </div>

                                                <div class="form-group row">
                                                    <label for="vegan" class="control-label col-sm-3 col-form-label col-form-label-sm">Vegan</label>
                                                    <div class="col-sm-8">
                                                        <input type="checkbox" class="form-check-input" name="vegan" id="vegan" :class="{'is-invalid': form.errors.has('vegan')}" v-model="form.vegan" style="position: static; margin-left: 0;">
                                                        <has-error :form="form" field="vegan"></has-error>
                                                    </div>
                                                </div>
                                                <div class="form-group row" style="color: #499360;">
                                                    <label for="vegetarisch" class="control-label col-sm-3 col-form-label col-form-label-sm">Vegetarisch</label>
                                                    <div class="col-sm-8">
                                                        <input type="checkbox" class="form-check-input" name="vegetarisch" id="vegetarisch" :class="{'is-invalid': form.errors.has('vegetarisch')}" v-model="form.vegetarisch" style="position: static; margin-left: 0;">
                                                        <has-error :form="form" field="vegetarisch"></has-error>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label for="glutenfrei" class="control-label col-sm-3 col-form-label col-form-label-sm">Glutenfrei</label>
                                                    <div class="col-sm-8">
                                                        <input type="checkbox" class="form-check-input" name="glutenfrei" id="glutenfrei" :class="{'is-invalid': form.errors.has('glutenfrei')}" v-model="form.glutenfrei" style="position: static; margin-left: 0;">
                                                        <has-error :form="form" field="glutenfrei"></has-error>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label for="laktosefrei" class="control-label col-sm-3 col-form-label col-form-label-sm">Laktosefrei</label>
                                                    <div class="col-sm-8">
                                                        <input type="checkbox" class="form-check-input" name="laktosefrei" id="laktosefrei" :class="{'is-invalid': form.errors.has('laktosefrei')}" v-model="form.laktosefrei" style="position: static; margin-left: 0;">
                                                        <has-error :form="form" field="laktosefrei"></has-error>
                                                    </div>
                                                </div>

                                                <div class="form-group row" style="color: #499360;">
                                                    <label for="xxl" class="control-label col-sm-3 col-form-label col-form-label-sm">Auch in XXL</label>
                                                    <div class="col-sm-8">
                                                        <input type="checkbox" class="form-check-input" name="xxl" id="xxl" :class="{'is-invalid': form.errors.has('xxl')}" v-model="form.xxl" style="position: static; margin-left: 0;">
                                                        <has-error :form="form" field="xxl"></has-error>
                                                    </div>
                                                </div>
                                                <div class="form-group row" style="color: #499360;">
                                                    <label for="potatos" class="control-label col-sm-3 col-form-label col-form-label-sm">Bei Kartoffeln</label>
                                                    <div class="col-sm-8">
                                                        <select name="potatos" id="potatos" v-model="form.potato_property" class="form-control form-control-sm">
                                                            <option :value="null">-- Bitte wählen -- </option>
                                                            <option value="festkochend">festkochend</option>
                                                            <option value="vorwiegend_festkochend">vorwiegend festkochend</option>
                                                            <option value="mehlig">mehlig</option>
                                                        </select>
                                                        <has-error :form="form" field="xxl"></has-error>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label for="voucher" class="control-label col-sm-3 col-form-label col-form-label-sm">Gutschein</label>
                                                    <div class="col-sm-8">
                                                        <input type="checkbox" class="form-check-input" name="voucher" id="voucher" :class="{'is-invalid': form.errors.has('voucher')}" v-model="form.voucher" style="position: static; margin-left: 0;" :disabled="article.hasVariations == 0 || article.greetingcard == 1">
                                                        <span v-if="article.hasVariations == 0">&nbsp;
                                                            <i class="fas fa-question-circle" id="tooltip-gutschein"></i>
                                                            <b-tooltip target="tooltip-gutschein" triggers="hover">
                                                                Kann erst aktiviert werden, wenn die Varianten aktiviert sind. Und muss deaktiviert werden, bevor die Varianten deaktiviert werden. 
                                                            </b-tooltip>  
                                                            <has-error :form="form" field="voucher"></has-error>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div class="my-4">
                                                    <b>Bei Obst & Gemüse:</b> 
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <div class="form-group"> 
                                                            <label for="fruit_property_type">Typ</label>
                                                            <select class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('fruit_property_type')}" name="fruit_property_type" id="fruit_property_type" v-model="form.fruit_property_type">
                                                                <option :value="null">-- Bitte wählen --</option>
                                                                <option :value="'size'">Größe</option>
                                                                <option :value="'reifegrad'">Reifegrad</option>
                                                            </select>
                                                            <has-error :form="form" field="fruit_property_type"></has-error>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div class="form-group">
                                                            <label for="fruit_property">Größe / Reifegrad / Farbe / ...:</label>
                                                            <input type="text" class="form-control form-control-sm" v-model="form.fruit_property" :class="{'is-invalid': form.errors.has('fruit_property')}">
                                                            <has-error :form="form" field="fruit_property"></has-error>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label for="article_commercial_class_id" class="control-label col-sm-3 col-form-label col-form-label-sm">Handelsklasse:</label>
                                                    <div class="col-sm-8">
                                                        <select name="article_commercial_class_id" id="article_commercial_class_id" class="form-control form-control-sm" v-model="form.article_commercial_class_id" :class="{'is-invalid': form.errors.has('article_commercial_class_id')}">
                                                            <option selected :value=null>-keine-</option>
                                                            <option v-for="classes in classes" :key="classes.id" v-bind:value="classes.id">{{classes.name}}</option>
                                                        </select>
                                                        <has-error :form="form" field="article_commercial_class_id"></has-error>
                                                    </div>
                                                </div>
                                            </fieldset>

                                        </div>
                                        <div class="col-md-4" style="display: flex; flex-direction: column">
                                            <fieldset class="p-2 mt-3 border" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                                                <legend class="w-auto">Versandeigenschaften</legend>
                                                
                                                <div class="form-group row" v-if="funke_mode == 'false'">
                                                    <label for="fragile" class="control-label col-sm-4 offset-sm-1 col-form-label col-form-label-sm">Bruchgut je Artikel (4,50&euro;)*</label>
                                                    <div class="col-sm-7">
                                                        <input type="checkbox" class="form-check-input" name="fragile" id="fragile" :class="{'is-invalid': form.errors.has('fragile')}" v-model="form.fragile" style="position: static; margin-left: 0;">
                                                        <has-error :form="form" field="fragile"></has-error>
                                                    </div>
                                                </div>

                                                <div class="form-group row" v-if="funke_mode == 'false'">
                                                    <label for="cargo" class="control-label col-sm-4 offset-sm-1 col-form-label col-form-label-sm">Sperrgut je Artikel (6,90&euro;)*</label>
                                                    <div class="col-sm-7">
                                                        <input type="checkbox" class="form-check-input" name="cargo" id="cargo" :class="{'is-invalid': form.errors.has('cargo')}" v-model="form.cargo" style="position: static; margin-left: 0;">
                                                        <has-error :form="form" field="cargo"></has-error>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label for="cool" class="control-label col-sm-4 offset-sm-1 col-form-label col-form-label-sm">Kühlversand pauschal (4,50&euro;)*</label>
                                                    <div class="col-sm-7">
                                                        <input type="checkbox" class="form-check-input" name="cool" id="cool" :class="{'is-invalid': form.errors.has('cool')}" v-model="form.cool" style="position: static; margin-left: 0;">
                                                        <has-error :form="form" field="cool"></has-error>
                                                    </div>
                                                </div>


                                                <div class="form-group row">
                                                    <label for="shippingfree" class="control-label col-sm-4 offset-sm-1 col-form-label col-form-label-sm">Versandfrei</label>
                                                    <div class="col-sm-7">
                                                        <input type="checkbox" class="form-check-input" name="shippingfree" id="shippingfree" :class="{'is-invalid': form.errors.has('shippingfree')}" v-model="form.shippingfree" style="position: static; margin-left: 0;">
                                                        <has-error :form="form" field="shippingfree"></has-error>
                                                    </div>
                                                </div>
                                                

                                            </fieldset>

                                        </div>
                                        <div class="col-md-4" style="display: flex; flex-direction: column">
                                            <fieldset class="p-2 mt-3 border" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                                                <legend class="w-auto">Bestandspflege</legend>
                                                <div class="table-responsive">
                                                    <table class="table table-striped">
                                                        <thead>
                                                            <th>Filiale</th>
                                                            <th>Soll-Bestand</th>
                                                            <th>Ist-Bestand</th>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="store in stores" :key="store.id">
                                                                <td>{{ store.name }}</td>
                                                                <td>
                                                                    <input type="number" class="form-control form-control-sm" min="1" max="100" step="1" v-model="form.stores[store.id]">
                                                                </td>
                                                                <td>-</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>


                                    <fieldset class="p-2 mt-3 border">
                                        <legend class="w-auto">Preispflege</legend>

                                        <div class="row">
                                            <div class="col-md-2">
                                                <div class="form-group">
                                                    <label for="useFactor" class="control-label col-form-label col-form-label-sm">Benutze Faktor?</label>
                                                    <select class="form-control form-control-sm" name="useFactor" id="useFactor" :class="{'is-invalid': form.errors.has('useFactor')}" v-model="form.useFactor">
                                                        <option :value="1">Ja</option>
                                                        <option :value="0">Nein</option>
                                                    </select>
                                                    <has-error :form="form" field="useFactor"></has-error>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div :class="form.useFactor == 1 ? 'col-md-1' : 'col-md-2'">
                                                <div class="form-group">
                                                    <label for="price" class="control-label col-form-label col-form-label-sm">Preis (Brutto)*</label>
                                                    <input type="number" class="form-control form-control-sm" :disabled="form.useFactor == 1" name="price" id="price" :class="{'is-invalid': form.errors.has('price')}" :value="calculatedPrice()" @change="updateMyPrice">
                                                    <has-error :form="form" field="price"></has-error>
                                                </div>
                                            </div>

                                            <div class="col-md-1" v-if="form.useFactor == 1">
                                                <div class="form-group">
                                                    <label for="factor" class="control-label col-form-label col-form-label-sm">Faktor*</label>
                                                    <input type="number" class="form-control form-control-sm" name="factor" id="factor" :class="{'is-invalid': form.errors.has('factor')}" v-model="form.factor">
                                                    <has-error :form="form" field="factor"></has-error>
                                                </div>
                                            </div>

                                            <div class="col-md-2">
                                                <div class="form-group">
                                                    <label for="pseudo_price" class="control-label col-form-label col-form-label-sm" :class="{'text-danger': form.pseudo_price >0}" >Sonderpreis (Brutto)</label>
                                                    <input type="number" class="form-control form-control-sm" name="pseudo_price" id="pseudo_price" :class="{'is-invalid': form.errors.has('pseudo_price')}" v-model="form.pseudo_price">
                                                    <has-error :form="form" field="pseudo_price"></has-error>
                                                </div>
                                            </div>

                                            <div class="col-md-2">
                                                <div class="form-group">
                                                    <label for="purchase_price" class="control-label col-form-label col-form-label-sm">Einkaufspreis (Netto)</label>
                                                    <input type="number" class="form-control form-control-sm" name="purchase_price" id="purchase_price" :class="{'is-invalid': form.errors.has('purchase_price')}" v-model="form.purchase_price">
                                                    <has-error :form="form" field="purchase_price"></has-error>
                                                </div>
                                            </div>

                                            <div class="col-md-2" v-if="notEmptyObject(article)">
                                                <div class="form-group">
                                                    <label class="control-label col-form-label col-form-label-sm" :class="{'text-danger': form.pseudo_price >0}">max. verfügbarer Rabatt % (Netto)</label>
                                                    <input type="number" style="background-color: #D3D3D3; opacity: 0.64;" class="form-control form-control-sm" :value="calculateMargePercent()" :class="{'is-invalid': form.errors.has('purchase_price')}" :disabled="true"><br>
                                                    <!-- <br><span :class="{'is-invalid': form.errors.has('purchase_price')}">{{ calculateMargePercent() }}</span> -->
                                                </div>
                                            </div>

                                            <div class="col-md-1" v-if="notEmptyObject(article)">
                                                <div class="form-group">
                                                    <label class="control-label col-form-label col-form-label-sm" :class="{'text-danger': form.pseudo_price >0}">Marge (absolut)</label>
                                                    <input type="number" style="background-color: #D3D3D3; opacity: 0.64;"  class="form-control form-control-sm" :value="calculateMargeAbsolut()" :class="{'is-invalid': form.errors.has('purchase_price')}" :disabled="true">
                                                    <!-- <br><span :class="{'is-invalid': form.errors.has('purchase_price')}">{{ calculateMargeAbsolut() }}</span> -->
                                                </div>
                                            </div>

                                            <div class="col-md-1" v-if="notEmptyObject(article)">
                                                <div class="form-group">
                                                    <label class="control-label col-form-label col-form-label-sm" :class="{'text-danger': form.pseudo_price >0}">Marge (%)</label>
                                                    <input type="number" style="background-color: #D3D3D3; opacity: 0.64;"  class="form-control form-control-sm" :value="calculateMargePer()" :class="{'is-invalid': form.errors.has('purchase_price')}" :disabled="true">
                                                    <!-- <br><span :class="{'is-invalid': form.errors.has('purchase_price')}">{{ calculateMargeAbsolut() }}</span> -->
                                                </div>
                                            </div>

                                            <div class="col-md-2">
                                                <div class="form-group">
                                                    <label for="store_price" class="control-label col-form-label col-form-label-sm">Filialpreis</label>
                                                    <input type="text" class="form-control form-control-sm" name="store_price" id="store_price" :class="{'is-invalid': form.errors.has('store_price')}" v-model="form.store_price">
                                                    <has-error :form="form" field="store_price"></has-error>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="row">
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label for="deposit_id" class="control-label col-form-label col-form-label-sm">Pfandart*</label>
                                                        <select name="deposit_id" id="deposit_id" class="form-control form-control-sm" v-model="form.deposit_id" :class="{'is-invalid': form.errors.has('deposit_id')}">
                                                            <option v-for="deposit in deposits" :key="deposit.id" v-bind:value="deposit.id">{{deposit.name}}</option>
                                                        </select>
                                                        <has-error :form="form" field="deposit_id"></has-error>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label for="deposit" class="control-label col-form-label col-form-label-sm">Pfandsumme</label>
                                                    <input type="text" class="form-control form-control-sm" name="deposit" id="deposit" :class="{'is-invalid': form.errors.has('deposit')}" v-model="form.deposit">
                                                    <has-error :form="form" field="deposit"></has-error>
                                                </div>
                                            </div>
                                            <div class="col-md-3"></div><div class="col-md-3"></div>
                                        </div>


                                        <div class="row">

                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label for="article_unit_id" class="control-label col-form-label col-form-label-sm">Grundeinheit*</label>
                                                        <select name="article_unit_id" id="article_unit_id" class="form-control form-control-sm" v-model="form.article_unit_id" :class="{'is-invalid': form.errors.has('article_unit_id')}">
                                                            <option v-for="unit in units" :key="unit.id" v-bind:value="unit.id">{{unit.description}}</option>
                                                        </select>
                                                        <has-error :form="form" field="article_unit_id"></has-error>
                                                </div>
                                            </div>

                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label for="purchaseunit" class="control-label col-form-label col-form-label-sm">Verpackung</label>
                                                        <input type="text" class="form-control form-control-sm" name="purchaseunit" id="purchaseunit" :class="{'is-invalid': form.errors.has('purchaseunit')}" v-model="form.purchaseunit">
                                                        <has-error :form="form" field="purchaseunit"></has-error>
                                                </div>
                                            </div>

                                            <div class="col-md-3">  
                                                <div class="form-group">
                                                    <label for="referenceunit" class="control-label col-form-label col-form-label-sm">
                                                        Referenz                                               
                                                    </label>
                                                    <input type="text" class="form-control form-control-sm" name="referenceunit" id="referenceunit" :class="{'is-invalid': form.errors.has('referenceunit')}" v-model="form.referenceunit">
                                                    <has-error :form="form" field="referenceunit"></has-error>
                                                </div>
                                            </div>

                                        </div>                                
                                        
                                    </fieldset>

                                    <fieldset class="p-2 mt-3 border">
                                        <legend class="w-auto">Produktpflege</legend>
                                        <div class="row">
                                            <div class="col-md-3">
                                                <div class="form-group row">
                                                    <label for="laststock" class="control-label col-sm-6 col-form-label col-form-label-sm">Aus dem Sortiment
                                                    <i class="fas fa-question-circle" id="tooltip-ads"></i>
                                                        <b-tooltip target="tooltip-ads" triggers="hover">
                                                            Artikel geht aus dem Sortiment &ndash; in der Front "letzte Chance". Bei Nullbestand Artikel löschen! 
                                                        </b-tooltip>  
                                                    </label>
                                                    <div class="col-sm-6">
                                                        <input type="checkbox" class="form-check-input" name="laststock" id="laststock" :class="{'is-invalid': form.errors.has('laststock')}" v-model="form.laststock" style="position: static; margin-left: 0;">
                                                        <has-error :form="form" field="laststock"></has-error>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label for="stock" class="control-label col-sm-6 col-form-label col-form-label-sm">Bestandspflege
                                                    </label>
                                                    <div class="col-sm-5">
                                                        <input type="text" class="form-control form-control-sm" name="stock" id="stock" :class="{'is-invalid': form.errors.has('stock')}" v-model="form.stock">
                                                        <has-error :form="form" field="stock"></has-error>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label for="not_available" class="control-label col-sm-6 col-form-label col-form-label-sm">Kurzfristig nicht lieferbar
                                                        <i class="fas fa-question-circle" id="tooltip-not-available"></i>
                                                        <b-tooltip target="tooltip-not-available" triggers="hover">
                                                            Zur Zeit nicht lieferbar - in der Font ist der Artikel nicht mehr bestellbar (Button deaktiviert), wird aber trotzdem noch angezeigt
                                                        </b-tooltip>  
                                                    </label>
                                                    <div class="col-sm-6">
                                                        <input type="checkbox" class="form-check-input" name="not_available" id="not_available" :class="{'is-invalid': form.errors.has('not_available')}" v-model="form.not_available" style="position: static; margin-left: 0;">
                                                        <has-error :form="form" field="not_available"></has-error>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label for="not_discountable" class="control-label col-sm-6 col-form-label col-form-label-sm">Nicht Rabattfähig
                                                        <i class="fas fa-question-circle" id="tooltip-not_discountable"></i>
                                                        <b-tooltip target="tooltip-not_discountable" triggers="hover">
                                                            Der Artikel kann z. B. durch einen Coupon nicht rabattiert werden! 
                                                        </b-tooltip> 
                                                    </label>
                                                    <div class="col-sm-6">
                                                        <input type="checkbox" class="form-check-input" name="not_discountable" id="not_discountable" :class="{'is-invalid': form.errors.has('not_discountable')}" v-model="form.not_discountable" style="position: static; margin-left: 0;">
                                                        <has-error :form="form" field="not_discountable"></has-error>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="maxpurchase" class="control-label col-sm-6 col-form-label col-form-label-sm">max. Verkauf
                                                        <i class="fas fa-question-circle" id="tooltip-maxpurchase"></i>
                                                        <b-tooltip target="tooltip-maxpurchase" triggers="hover">
                                                            Wie viele Artikel werden Maximal pro Bestellung verkauft?
                                                        </b-tooltip> 
                                                    </label>
                                                    <div class="col-sm-5">
                                                        <input type="text" class="form-control form-control-sm" name="maxpurchase" id="maxpurchase" :class="{'is-invalid': form.errors.has('maxpurchase')}" v-model="form.maxpurchase">
                                                        <has-error :form="form" field="maxpurchase"></has-error>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col-md-3">

                                                <div class="form-group row">
                                                    <label for="seasonal" class="control-label col-sm-6 col-form-label col-form-label-sm">Saisonartikel</label>
                                                    <div class="col-sm-6">
                                                        <input type="checkbox" class="form-check-input" name="seasonal" id="seasonal" :class="{'is-invalid': form.errors.has('seasonal')}" v-model="form.seasonal" style="position: static; margin-left: 0;">
                                                        <has-error :form="form" field="seasonal"></has-error>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label for="topseller" class="control-label col-sm-6 col-form-label col-form-label-sm">Artikel hervorheben?</label>
                                                    <div class="col-sm-6">
                                                        <input type="checkbox" class="form-check-input" name="topseller" id="topseller" :class="{'is-invalid': form.errors.has('topseller')}" v-model="form.topseller" style="position: static; margin-left: 0;">
                                                        <has-error :form="form" field="topseller"></has-error>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label for="sample_picture" class="control-label col-sm-6 col-form-label col-form-label-sm">Musterbild</label>
                                                    <div class="col-sm-6">
                                                        <input type="checkbox" class="form-check-input" name="sample_picture" id="sample_picture" :class="{'is-invalid': form.errors.has('sample_picture')}" v-model="form.sample_picture" style="position: static; margin-left: 0;">
                                                        <has-error :form="form" field="sample_picture"></has-error>
                                                    </div>
                                                </div>

                                                <!-- <div class="form-group row">
                                                    <label for="backside" class="control-label col-sm-6 col-form-label col-form-label-sm">Rückseite</label>
                                                    <div class="col-sm-6">
                                                        <input type="checkbox" class="form-check-input" name="backside" id="backside" :class="{'is-invalid': form.errors.has('backside')}" v-model="form.backside" style="position: static; margin-left: 0;">
                                                        <has-error :form="form" field="backside"></has-error>
                                                    </div>
                                                </div> -->

                                            </div>

                                            <div class="col-md-6">

                                                <div class="form-group row">
                                                    <label for="shippingtime" class="control-label col-sm-3 offset-sm-1 col-form-label col-form-label-sm">
                                                        Lieferzeit (in Tagen) 
                                                        <i class="fas fa-question-circle" v-b-tooltip.hover title="Nur die Anzahl der Tage eintragen (z. B. 1 oder 1-4) Voreingestellt: 1-4 Tage"></i>
                                                    </label>
                                                    <div class="col-sm-8">
                                                        <input placeholder="1 - 4" type="text" class="form-control form-control-sm" name="shippingtime" id="shippingtime" :class="{'is-invalid': form.errors.has('shippingtime')}" v-model="form.shippingtime">
                                                        <has-error :form="form" field="shippingtime"></has-error>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label for="publishDate" class="control-label col-sm-3 offset-sm-1 col-form-label col-form-label-sm">Einstelldatum
                                                        <i class="fas fa-question-circle" id="tooltip-publishDate"></i>
                                                        <b-tooltip target="tooltip-publishDate" triggers="hover">
                                                            Ab diesem Tag, ist der Artikel in der Front sichtbar. Allerdings kann der Artikel erst bestellt werden, wenn der Artikel lieferbar ist ("Lieferbar ab").
                                                        </b-tooltip> 
                                                    </label>
                                                    <div class="col-sm-8">
                                                        <input type="date" class="form-control form-control-sm" name="publishDate" id="publishDate" :class="{'is-invalid': form.errors.has('publishDate')}" v-model="form.publishDate">
                                                        <has-error :form="form" field="publishDate"></has-error>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label for="releaseDate" class="control-label col-sm-3 offset-sm-1 col-form-label col-form-label-sm">Lieferbar ab
                                                    <i class="fas fa-question-circle" id="tooltip-releaseDate"></i>
                                                        <b-tooltip target="tooltip-releaseDate" triggers="hover">
                                                            Ab diesem Tag ist der Artikel bestellbar. (Funktioniert in Kombination mit dem Einstelldatum)
                                                        </b-tooltip> 
                                                    </label>
                                                    <div class="col-sm-8">
                                                        <input type="date" class="form-control form-control-sm" name="releaseDate" id="releaseDate" :class="{'is-invalid': form.errors.has('releaseDate')}" v-model="form.releaseDate">
                                                        <has-error :form="form" field="releaseDate"></has-error>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label for="mhd" class="control-label col-sm-3 offset-sm-1 col-form-label col-form-label-sm">MHD kritisch
                                                    <i class="fas fa-question-circle" id="tooltip-mhd"></i>
                                                        <b-tooltip target="tooltip-mhd" triggers="hover">
                                                            MHD wird nach dem Artikelname angezeigt  
                                                        </b-tooltip>  
                                                    </label>
                                                    <div class="col-sm-8">
                                                        <input type="date" class="form-control form-control-sm" name="mhd" id="mhd" :class="{'is-invalid': form.errors.has('mhd')}" v-model="form.mhd">
                                                        <has-error :form="form" field="mhd"></has-error>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>

                                    
                                        
                                    <fieldset class="p-2 mt-3 border">
                                        <legend class="w-auto">Beschreibung</legend>
                                        <div class="form-group">
                                            <label for="description_long" class="control-label col-sm-2 col-form-label col-form-label-sm">Beschreibung</label>
                                            <ckeditor :editor="editor" :config="editorConfig" v-model="form.description_long" :class="{'is-invalid': form.errors.has('description_long')}" v-if="notEmptyObject(form)"></ckeditor>
                                            <has-error :form="form" field="description_long"></has-error>
                                        </div>
                                    </fieldset>

                                    
                                    <div class="mt-3 row">
                                        <div class="text-right col-md-12">
                                            <button type="button" class="btn btn-primary btn-sm" @click="saveArticle">
                                                <i class="fas fa-save"></i> Speichern
                                            </button>
                                        </div>
                                    </div>
                                </b-tab>

                                <b-tab title="Bilder">
                                    <div class="row ">
                                    <div class="col-md-6">
                                        <fieldset class="p-2 mt-3 border">
                                        <legend class="w-auto">Bilder hochladen</legend>
                                            <div class="form-group">
                                                <label for="logo" class="control-label">Bilder</label>
                                                <b-form-file multiple id="images" v-model="uploadImageForm.images" :class="{'is-invalid': uploadImageForm.errors.has('images') || uploadImageForm.errors.has('images.0')}" />
                                                <input type="hidden" v-model="uploadImageForm.article_id" name="article_id" id="article_id" />
                                                <has-error :form="uploadImageForm" field="images"></has-error>
                                                <has-error :form="uploadImageForm" field="images.0"></has-error>
                                            </div>

                                            <div class="form-group">
                                                <button type="button" class="btn btn-primary btn-sm" @click="saveImages">
                                                        <i class="fas fa-save"></i> Bilder hochladen
                                                </button>
                                            </div>
                                        </fieldset>

                                        <fieldset class="p-2 mt-3 border">
                                        <legend class="w-auto">Zugewiesene Bilder</legend>

                                            <div class="row" v-if="article.media != null">
                                                
                                                <div class="mb-2 text-center border col-md-4 border-primary"  :class="{ 'border-white': imageSelected != index}" @click="selectImage(index)" v-for="(image, index) in article.media" :key="image.uuid" style="width:180px; height: 180px; border-width: 3px;">
                                                    <img :src="image.original_url" style="height: 100%;"/>
                                                    <div class="overlay" v-if="image.custom_properties.active == 'false'">
                                                        <div class="text">Deaktiviert</div>
                                                    </div>
                                                    <div class="ribbon-wrapper ribbon-lg" v-if="image.custom_properties.preview == 'true'">
                                                        <div class="ribbon bg-primary">
                                                        Vorschau
                                                        </div>
                                                    </div>
                                                    <div class="ribbon-wrapper ribbon-lg" v-if="image.custom_properties.preview_hover == 'true'">
                                                        <div class="ribbon bg-primary">
                                                            Hover
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>

                                            <div class="row" v-if="article.media == []">
                                                <h6>Es wurde noch keine Bilder für diesen Artikel hochgeladen</h6>
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div class="col-md-6">
                                        <fieldset class="p-2 mt-3 border" v-if="selectedImage.original_url != null">
                                            <legend class="w-auto">Bildoptionen</legend>
                                                <div class="row">
                                                    <div class="text-center col-md-12">
                                                        <img :src="selectedImage.original_url" style="width: 50%;"/>
                                                        
                                                    </div>
                                                </div>
                                                <div class="mt-3 row">
                                                    <div class="col-md-12">
                                                        <div class="text-left form-group row">
                                                            <label for="title" class="control-label col-sm-2 col-form-label col-form-label-sm">Titel:</label>
                                                            <div class="col-sm-10">
                                                                <input type="text" name="title" id="title" class="form-control form-control-sm" v-model="imageDataForm.title" :class="{'is-invalid': imageDataForm.errors.has('title')}">
                                                                <has-error :form="imageDataForm" field="title"></has-error>
                                                            </div>
                                                        </div>
                                                        <div class="text-left form-group row">
                                                            <label for="title" class="control-label col-sm-2 col-form-label col-form-label-sm">Aktiv:</label>
                                                            <div class="col-sm-10">
                                                                <select class="form-control form-control-sm" v-model="imageDataForm.active" :class="{'is-invalid': imageDataForm.errors.has('active')}">
                                                                    <option value="1">Ja</option>
                                                                    <option value="0">Nein</option>
                                                                </select>
                                        
                                                                <has-error :form="imageDataForm" field="active"></has-error>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="mt-3 row">
                                                    <div class="text-right col-md-12">
                                                        <button type="button" class="mr-1 btn btn-secondary btn-sm" @click="openSettingsModal(selectedImage.uuid)" v-if="article.hasVariations == 1">
                                                            <i class="fas fa-cog"></i> Konfigurieren
                                                        </button>
                                                        <button type="button" class="mr-1 btn btn-danger btn-sm" @click="deleteImage(selectedImage.uuid)" v-if="selectedImage.custom_properties.preview == 'false'">
                                                            <i class="fas fa-trash"></i> Löschen
                                                        </button>
                                                        <button type="button" class="mr-1 btn btn-primary btn-sm" @click="setDefaultImage(selectedImage.uuid)" 
                                                            v-if="selectedImage.custom_properties.preview_hover == 'false'
                                                                && selectedImage.custom_properties.preview == 'false' 
                                                                && selectedImage.custom_properties.active == 'true'">
                                                            <i class="fas fa-save"></i> Als Standard Vorschau
                                                        </button>
                                                        <button type="button" id="tooltip-hover" class="mr-1 btn btn-primary btn-sm" @click="setDefaultHoverImage(selectedImage.uuid, 0)" v-if="selectedImage.custom_properties.preview == 'false' && selectedImage.custom_properties.preview_hover == 'false' && selectedImage.custom_properties.active == 'true'">
                                                            <i class="fas fa-save"></i> Als Standard Hover festlegen
                                                            <b-tooltip target="tooltip-hover" triggers="hover">
                                                                Wird angezeigt, wenn jemand mit der Maus über das Vorschaubild fährt
                                                            </b-tooltip>  
                                                        </button>
                                                        <button type="button" class="mr-1 btn btn-warning btn-sm" v-if="selectedImage.custom_properties.preview_hover == 'true'" @click="setDefaultHoverImage(selectedImage.uuid, 1)">Standard Hover entfernen</button>
                                                        <button type="button" class="btn btn-primary btn-sm" @click="saveImageData">
                                                            <i class="fas fa-save"></i> Speichern
                                                        </button>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                </b-tab>

                                <b-tab title="Varianten" :disabled="!article.hasVariations">
                                    <fieldset class="p-2 mt-3 border">
                                        <legend class="w-auto">Vorhandene Varianten</legend>
                                        <b-col v-if="form.voucher != 0" md="12" class="text-center" style="color: red;">
                                            <h5>ACHTUNG! VARIANTE BEARBEITEN UND GUTSCHEIN-MOTIV WÄHLEN! </h5>
                                        </b-col>
                                        <b-col md="12" class="mb-2 text-right">
                                            <b-button size="sm" variant="primary" @click="createModal">Variante erstellen</b-button>
                                        </b-col>
                                        <b-row v-if="existingVariations.length >= 1">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Bestellnummer</th>
                                                    <th v-for="group in article.variation_groups" :key="group.id">{{ group.name }}</th>
                                                    <!-- <th v-if="form.voucher != 0">Ausgewähltes Motiv</th> -->
                                                    <th>Preis</th>
                                                    <th>Angebotspreis</th>
                                                    <th>Vorauswahl</th>
                                                    <th>Aktiv</th>
                                                    <th>Aktion</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr v-for="(existingVariation, index) in existingVariations" :key="existingVariation.id">
                                                    <td>{{existingVariation.ordernumber}}</td>
                                                    <td v-for="group in article.variation_groups" :key="group.id">
                                                        {{ getVariationValue(index, group.id) }}
                                                    </td>
                                                    <!-- <td v-if="form.voucher != 0">{{ existingVariation.voucher_templates.title }}</td> -->
                                                    <td>
                                                        <template v-if="existingVariation.useFactor == 1">
                                                            {{ Number(parseFloat(existingVariation.purchase_price * existingVariation.factor).toFixed(2)) | toCurrency }} (Faktor)
                                                        </template>
                                                        <template v-if="existingVariation.useFactor == 0">
                                                            {{ existingVariation.price | toCurrency }}
                                                        </template>

                                                    </td>
                                                    <td>{{existingVariation.pseudo_price | toCurrency }}</td>
                                                    <td>
                                                        <i v-if="existingVariation.id == article.main_detail_id" class="fas fa-check"></i>
                                                        <i v-else class="fas fa-times"></i>
                                                    </td>
                                                    <td>
                                                        <i v-if="existingVariation.active == 1" class="fas fa-check"></i>
                                                        <i v-else class="fas fa-times"></i>
                                                    </td>
                                                    <td>
                                                        <b-button size="sm" @click="labelModal(existingVariation.id)" class="mr-1" variant="info" v-if="$auth.check('articles.edit')"><i class="fas fa-print" ></i></b-button>
                                                        <b-button size="sm" @click="editModal(existingVariation)" class="mr-1" variant="warning" v-if="$auth.check('articles.edit')"><i class="fas fa-edit" ></i></b-button>
                                                        <b-button size="sm" @click="deleteVariationValue(existingVariation.id)" variant="danger" v-if="$auth.check('articles.destroy')"><i class="fas fa-trash"></i></b-button>
                                                    </td>
                                                </tr>
                                            </tbody>

                                        </table>
                                        </b-row>

                                        <b-row v-if="existingVariations.length == 0">
                                            <b-col><h6><i>Es sind derzeit keine Varianten angelegt</i></h6></b-col>
                                        </b-row>
                                    </fieldset>
                                </b-tab>

                                <b-tab title="Stückliste" :disabled="!article.bundle">
                                    <Bundle :article="article" :producers="producers" :taxes="taxes" v-on:load_articles="loadArticle" v-if="notEmptyObject(article) && notEmptyObject(producers) && notEmptyObject(taxes)" />
                                </b-tab>

                                <b-tab title="Nährwerte">
                                    <fieldset class="p-2 mt-3 border">
                                        <legend class="w-auto">Nährwerte</legend>
                                        <div class="mb-3 row">
                                            <div class="text-right col-md-12">
                                                <b-button class="mt-2" size="sm" @click="saveNutrition($route.params.id)" variant="primary"><i class="fas fa-save"></i> Nährwerte speichern</b-button>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                
                                                <div class="form-group row">
                                                    <label for="kj" class="control-label col-sm-2 col-form-label col-form-label-sm">KJ</label>
                                                    <div class="col-sm-9">
                                                        <input type="number" name="kj" id="kj" class="form-control form-control-sm" :class="{'is-invalid': nutrition.errors.has('kj')}" v-model="nutrition.kj" />
                                                        <has-error :form="nutrition" field="kj"></has-error>
                                                    </div>
                                                </div> 

                                                <div class="form-group row">
                                                    <label for="kcal" class="control-label col-sm-2 col-form-label col-form-label-sm">Kcal</label>
                                                    <div class="col-sm-9">
                                                        <input type="number" name="kcal" id="kcal" class="form-control form-control-sm" :class="{'is-invalid': nutrition.errors.has('kcal')}" v-model="nutrition.kcal" />
                                                        <has-error :form="nutrition" field="kcal"></has-error>
                                                    </div>
                                                </div> 

                                                <div class="form-group row">
                                                    <label for="fett" class="control-label col-sm-2 col-form-label col-form-label-sm">Fett</label>
                                                    <div class="col-sm-9">
                                                        <input type="number" name="fett" id="fett" class="form-control form-control-sm" :class="{'is-invalid': nutrition.errors.has('fett')}" v-model="nutrition.fett" />
                                                        <has-error :form="nutrition" field="fett"></has-error>
                                                    </div>
                                                </div> 

                                                <div class="form-group row">
                                                    <label for="fettsaeuren" class="control-label col-sm-2 col-form-label col-form-label-sm">gesättigte Fettsäuren</label>
                                                    <div class="col-sm-9">
                                                        <input type="number" name="fettsaeuren" id="fettsaeuren" class="form-control form-control-sm" :class="{'is-invalid': nutrition.errors.has('fettsaeuren')}" v-model="nutrition.fettsaeuren" />
                                                        <has-error :form="nutrition" field="fettsaeuren"></has-error>
                                                    </div>
                                                </div> 

                                                <div class="form-group row">
                                                    <label for="kohlenhydrate" class="control-label col-sm-2 col-form-label col-form-label-sm">Kohlenhydrate</label>
                                                    <div class="col-sm-9">
                                                        <input type="number" name="kohlenhydrate" id="kohlenhydrate" class="form-control form-control-sm" :class="{'is-invalid': nutrition.errors.has('kohlenhydrate')}" v-model="nutrition.kohlenhydrate" />
                                                        <has-error :form="nutrition" field="kohlenhydrate"></has-error>
                                                    </div>
                                                </div> 

                                                <div class="form-group row">
                                                    <label for="zucker" class="control-label col-sm-2 col-form-label col-form-label-sm">Zucker</label>
                                                    <div class="col-sm-9">
                                                        <input type="number" name="zucker" id="zucker" class="form-control form-control-sm" :class="{'is-invalid': nutrition.errors.has('zucker')}" v-model="nutrition.zucker" />
                                                        <has-error :form="nutrition" field="zucker"></has-error>
                                                    </div>
                                                </div> 

                                            </div>

                                            <div class="col-md-6">

                                                <div class="form-group row">
                                                    <label for="eiweiss" class="control-label col-sm-2 col-form-label col-form-label-sm">Eiweiss</label>
                                                    <div class="col-sm-9">
                                                        <input type="number" name="eiweiss" id="eiweiss" class="form-control form-control-sm" :class="{'is-invalid': nutrition.errors.has('eiweiss')}" v-model="nutrition.eiweiss" />
                                                        <has-error :form="nutrition" field="eiweiss"></has-error>
                                                    </div>
                                                </div> 

                                                <div class="form-group row">
                                                    <label for="salz" class="control-label col-sm-2 col-form-label col-form-label-sm">Salz</label>
                                                    <div class="col-sm-9">
                                                        <input type="number" name="salz" id="salz" class="form-control form-control-sm" :class="{'is-invalid': nutrition.errors.has('salz')}" v-model="nutrition.salz" />
                                                        <has-error :form="nutrition" field="salz"></has-error>
                                                    </div>
                                                </div> 

                                                <div class="form-group row">
                                                    <label for="ballaststoffe" class="control-label col-sm-2 col-form-label col-form-label-sm">Ballaststoffe</label>
                                                    <div class="col-sm-9">
                                                        <input type="number" name="ballaststoffe" id="ballaststoffe" class="form-control form-control-sm" :class="{'is-invalid': nutrition.errors.has('ballaststoffe')}" v-model="nutrition.ballaststoffe" />
                                                        <has-error :form="nutrition" field="ballaststoffe"></has-error>
                                                    </div>
                                                </div> 

                                                <div class="form-group row">
                                                    <label for="alkohol" class="control-label col-sm-2 col-form-label col-form-label-sm">Alkohol</label>
                                                    <div class="col-sm-9">
                                                        <input type="number" name="alkohol" id="alkohol" class="form-control form-control-sm" :class="{'is-invalid': nutrition.errors.has('alkohol')}" v-model="nutrition.alkohol" />
                                                        <has-error :form="nutrition" field="alkohol"></has-error>
                                                    </div>
                                                </div> 

                                                <div class="form-group row">
                                                    <label for="nutri_score" class="control-label col-sm-2 col-form-label col-form-label-sm">Nutriscore</label>
                                                    <div class="col-sm-9">
                                                        <select name="nutri_score" id="nutri_score" class="form-control form-control-sm" :class="{'is-invalid': nutrition.errors.has('nutri_score')}" v-model="nutrition.nutri_score">
                                                            <option :value="''">--</option>
                                                            <option value="a">A</option>
                                                            <option value="b">B</option>
                                                            <option value="c">C</option>
                                                            <option value="d">D</option>
                                                            <option value="e">E</option>
                                                        </select>
                                                        <has-error :form="nutrition" field="nutri_score"></has-error>
                                                    </div>
                                                </div> 

                                                <div class="form-group row">
                                                    <label for="je" class="control-label col-sm-2 col-form-label col-form-label-sm">Je</label>
                                                    <div class="col-sm-9">
                                                        <input type="text" name="je" id="je" class="form-control form-control-sm" :class="{'is-invalid': nutrition.errors.has('je')}" v-model="nutrition.je" />
                                                        <has-error :form="nutrition" field="je"></has-error>
                                                    </div>
                                                </div> 

                                            </div>
                                        </div>
                                    </fieldset>

                                    <fieldset class="p-2 border">
                                        <legend class="w-auto">Zusatzangaben</legend>
                                        <div class="row">
                                            <div class="col-md-6">

                                                <div class="form-group row">
                                                    <label for="zutaten" class="control-label col-sm-2 col-form-label col-form-label-sm">Zutaten</label>
                                                    <div class="col-sm-9">
                                                        <ckeditor :editor="editor" :config="editorConfig" v-model="nutrition.zutaten" :class="{'is-invalid': nutrition.errors.has('zutaten')}"></ckeditor>
                                                        <has-error :form="nutrition" field="zutaten"></has-error>
                                                    </div>
                                                </div> 
                                                
                                            </div>

                                            <div class="col-md-6">
                                                <div class="form-group row">
                                                    <label for="inverkehrbringer" class="control-label col-sm-2 col-form-label col-form-label-sm">Inverkehrbringer</label>
                                                    <div class="col-sm-9">
                                                        <input type="text" name="inverkehrbringer" id="inverkehrbringer" class="form-control form-control-sm" :class="{'is-invalid': nutrition.errors.has('inverkehrbringer')}" v-model="nutrition.inverkehrbringer" />
                                                        <has-error :form="nutrition" field="inverkehrbringer"></has-error>
                                                    </div>
                                                </div> 

                                                <div class="form-group row">
                                                    <label for="herkunft" class="control-label col-sm-2 col-form-label col-form-label-sm">Herkunft</label>
                                                    <div class="col-sm-9">
                                                        <input type="text" name="herkunft" id="herkunft" class="form-control form-control-sm" :class="{'is-invalid': nutrition.errors.has('herkunft')}" v-model="nutrition.herkunft" />
                                                        <has-error :form="nutrition" field="herkunft"></has-error>
                                                    </div>
                                                </div> 
                                                <div class="form-group row">
                                                    <label for="country" class="control-label col-sm-2 col-form-label col-form-label-sm">Herkunftsland:</label>
                                                    <div class="col-sm-9" v-if="countries.length > 0">
                                                        <v-select label="country" :get-option-label="(country) => country.country + ' (' + country.iso2 + ' | ' + country.iso3 + ')'" :options="countries" :reduce="country => country.id" v-model="nutrition.country_id" :class="{'is-invalid': nutrition.errors.has('country')}"></v-select>
                                                        <has-error :form="form" field="country"></has-error>
                                                    </div>
                                                </div>

                                                <div class="form-group row"> 
                                                    <label for="bez" class="control-label col-sm-2 col-form-label col-form-label-sm">Bezeichnung</label>
                                                    <div class="col-sm-9">
                                                        <input type="text" name="bez" id="bez" class="form-control form-control-sm" :class="{'is-invalid': nutrition.errors.has('bez')}" v-model="nutrition.bez" />
                                                        <has-error :form="nutrition" field="bez"></has-error>
                                                    </div>
                                                </div> 
                                            </div>
                                        </div>
                                    </fieldset>
                                    <div class="mt-3 row">
                                        <div class="text-right col-md-12">
                                            <b-button class="mt-2" size="sm" @click="saveNutrition($route.params.id)" variant="primary"><i class="fas fa-save"></i> Nährwerte speichern</b-button>
                                        </div>
                                    </div>
                                </b-tab>

                                <b-tab title="SEO">
                                    <Advanced :article="article" v-if="notEmptyObject(article)" v-on:articleUpdate="loadArticle()"/>
                                </b-tab>

                                <b-tab title="Statistik">
                                    <Sales :article="article" v-if="notEmptyObject(article)"/>
                                </b-tab>

                                <b-tab v-for="company in companies" :key="company.id" :title="company.name" lazy>
                                    <Company :company="company" :article="article" :taxes="taxes" :producers="producers" v-on:articleUpdate="loadArticle()" :key="company.id"/>
                                </b-tab>

                                <b-tab title="Marktplätze" lazy>
                                    <Marketplace :article="article" v-on:articleUpdate="loadArticle()"/>
                                </b-tab>
                            </b-tabs>
                        </b-card>
                    </div>
                </div>
            </div>
        </div>


        <b-modal id="labelModal" title="Artikeletiketten drucken" ok-only ok-variant="danger" ok-title="Drucken" size="l" @ok="doLabels">
            <div class="form-group">
                <label>Modus</label>
                <select class="form-control form-control-sm" v-model="label.mode" :disabled="usePrintnode == 'false'">
                    <option value="print">Direkt drucken</option>
                    <option value="pdf">Download als PDF</option>
                </select>
            </div>

            <div class="form-group" v-if="label.mode == 'print'">
                <label>Filiale</label>
                <select class="form-control form-control-sm" v-model="label.printer">
                    <option v-for="printer in printers" :key="printer.id" :value="printer.id">{{ printer.name }}</option>
                </select>
            </div>

            <div class="form-group">
                <label>Anzahl pro Etikett</label>
                <input type="number" class="form-control form-control-sm" v-model="label.qty" />
            </div>

            <!-- <template #modal-footer="{ ok }">
                <b-button size="sm" variant="primary" @click="ok()">
                    OK
                </b-button>
            </template> -->

        </b-modal>

        <b-modal v-if="notEmptyObject(article)" id="variationModal" title="Artikelvariante anlegen" ok-only ok-variant="danger" ok-title="Speichern" size="sm" @ok="handleOk">
            <form ref="valueForm" @submit.stop.prevent="handleSubmit">
                <div class="row">

                    <div class="col-md-12">
                        <div class="form-group row">
                            <label class="control-label col-sm-2 col-form-label col-form-label-sm">Variantengruppe</label>
                            <div class="col-sm-12">
                                <!-- <input v-model="valueForm.name" type="text" class="form-control form-control-sm" :class="{'is-invalid': valueForm.errors.has('name')}" tabindex="1"/> -->
                                <!-- <has-error :form="valueForm" field="name"></has-error> -->
                                <select class="form-control form-control-sm" @change="updateValues" :disabled="article.variation_groups.length > 0">
                                    <option v-for="variationGroup in variationGroups" :key="variationGroup.id" :value="variationGroup.id">{{variationGroup.name}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="control-label col-sm-2 col-form-label col-form-label-sm">Variantenoptionen</label>
                            <template v-for="variationValue in variationValues">
                                <div class="form-check" :key="variationValue.id" v-if="variationForm.hiddenVariationsValues.includes(variationValue.id) == false">
                                    <input type="checkbox" class="form-check-input" :value="variationValue.id" v-model="variationForm.selectedVariationValues" :class="{'is-invalid': variationForm.errors.has('selectedVariationValues')}">
                                    <label class="form-check-label">{{variationValue.name}}</label>
                                    <has-error :form="variationForm" field="selectedVariationValues"></has-error>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </form>
        </b-modal>

        <b-modal id="editModal" title="Artikeldetails editieren" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleDetailOk($event, detailForm.id)">
            <form ref="valueForm" @submit.stop.prevent="handleDetailSubmit">
                <fieldset class="p-2 border">
                                <legend class="w-auto">Stammdaten</legend>
                                <div class="row">
                                    <div class="col-md-6">

                                        <div class="form-group row">
                                            <label for="ordernumber" class="control-label col-sm-3 col-form-label col-form-label-sm">Artikelnummer*</label>
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control form-control-sm" name="ordernumber" id="ordernumber" :class="{'is-invalid': detailForm.errors.has('ordernumber')}" v-model="detailForm.ordernumber">
                                                <has-error :form="detailForm" field="ordernumber"></has-error>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label for="active" class="control-label col-sm-3 col-form-label col-form-label-sm">Aktiv</label>
                                            <div class="col-sm-9">
                                                <input type="checkbox" class="form-check-input" name="active" id="active" :class="{'is-invalid': detailForm.errors.has('active')}" v-model="detailForm.active" style="position: static; margin-left: 0;">
                                                <has-error :form="detailForm" field="active"></has-error>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="col-md-6">

                                        <div class="form-group row">
                                            <label for="han" class="control-label col-sm-3 offset-sm-1 col-form-label col-form-label-sm">Lieferantenartikelnr.</label>
                                            <div class="col-sm-8">
                                                <input type="text" class="form-control form-control-sm" name="han" id="han" :class="{'is-invalid': detailForm.errors.has('han')}" v-model="detailForm.han">
                                                <has-error :form="detailForm" field="han"></has-error>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label for="ean" class="control-label col-sm-3 offset-sm-1 col-form-label col-form-label-sm">EAN</label>
                                            <div class="col-sm-8">
                                                <input type="text" class="form-control form-control-sm" name="ean" id="ean" :class="{'is-invalid': detailForm.errors.has('ean')}" v-model="detailForm.ean">
                                                <has-error :form="detailForm" field="ean"></has-error>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div v-if="form.voucher != 0" class="form-group row">
                                            <label class="control-label col-sm-3 col-form-label col-form-label-sm" style="color: red;">Motiv*</label>
                                            <div class="col-sm-9">
                                                <select name="voucher_template_id" id="voucher_template_id" class="form-control form-control-sm" v-model="detailForm.voucher_template_id" :class="{'is-invalid': detailForm.errors.has('voucher_template_id')}">
                                                    <option v-for="voucher_template in voucher_templates" :key="voucher_template.id" :value="voucher_template.id">{{ voucher_template.title }}</option>
                                                </select>
                                                <has-error :form="detailForm" field="voucher_template_id"></has-error>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </fieldset>

                            <div class="row">
                                <div class="col-md-6" style="display: flex; flex-direction: column">
                                    <fieldset class="p-2 border" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                                        <legend class="w-auto">Versandeigenschaften</legend>
                                        <div class="row">
                                            
                                            <div class="col-md-12">

                                                <div class="form-group row" v-if="funke_mode == 'false'">
                                                    <label for="fragile" class="control-label col-sm-4 col-form-label col-form-label-sm">Bruchgut je Artikel (4,50&euro;)*</label>
                                                    <div class="col-sm-8">
                                                        <input type="checkbox" class="form-check-input" name="fragile" id="fragile" :class="{'is-invalid': detailForm.errors.has('fragile')}" v-model="detailForm.fragile" style="position: static; margin-left: 0;">
                                                        <has-error :form="detailForm" field="fragile"></has-error>
                                                    </div>
                                                </div>

                                                <div class="form-group row" v-if="funke_mode == 'false'">
                                                    <label for="cargo" class="control-label col-sm-4 col-form-label col-form-label-sm">Sperrgut je Artikel (6,90&euro;)*</label>
                                                    <div class="col-sm-8">
                                                        <input type="checkbox" class="form-check-input" name="cargo" id="cargo" :class="{'is-invalid': detailForm.errors.has('cargo')}" v-model="detailForm.cargo" style="position: static; margin-left: 0;">
                                                        <has-error :form="detailForm" field="cargo"></has-error>
                                                    </div>
                                                </div>

                                                <div class="form-group row">
                                                    <label for="shippingfree" class="control-label col-sm-4 col-form-label col-form-label-sm">Versandfrei</label>
                                                    <div class="col-sm-8">
                                                        <input type="checkbox" class="form-check-input" name="shippingfree" id="shippingfree" :class="{'is-invalid': detailForm.errors.has('shippingfree')}" v-model="detailForm.shippingfree" style="position: static; margin-left: 0;">
                                                        <!-- <select name="shippingfree" id="shippingfree" class="form-control form-control-sm" v-model="form.shippingfree" :class="{'is-invalid': form.errors.has('shippingfree')}">
                                                            <option value="0">Nein</option>
                                                            <option value="1">Ja</option>
                                                        </select> -->
                                                        <has-error :form="detailForm" field="shippingfree"></has-error>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </fieldset>
                                </div>

                                <div class="col-md-6" style="display: flex; flex-direction: column">
                                    <fieldset class="p-2 border" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                                        <legend class="w-auto">Bestandspflege</legend>
                                        <div class="table-responsive">
                                            <table class="table table-striped">
                                                <thead>
                                                    <th>Filiale</th>
                                                    <th>Soll-Bestand</th>
                                                    <th>Ist-Bestand</th>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="store in stores" :key="store.id">
                                                        <td>{{ store.name }}</td>
                                                        <td>
                                                            <input type="number" class="form-control form-control-sm" min="1" max="100" step="1" v-model="detailForm.stores[store.id]">
                                                        </td>
                                                        <td>-</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <fieldset class="p-2 mt-3 border" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                                        <legend class="w-auto">Regalzuweisung</legend>
                                        <button class="btn btn-primary btn-xs" type="button" @click="addDetailShelf"><i class="fas fa-fw fa-box"></i>Neues Regal</button>
                                        <template v-if="detailForm.shelves.length == 0">
                                            <h5>Derzeit sind diesem Artikel keine Lagerplätze zugewiesen.</h5>
                                        </template>

                                        <template v-if="detailForm.shelves.length >= 1">
                                            <table class="table table-striped table-sm">
                                                <thead>
                                                    <th>Regal</th>
                                                    <th>Ebene</th>
                                                    <th>Aktionen</th>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(shelf, index) in detailForm.shelves" :key="shelf.id">
                                                        <td>
                                                            <!-- <input type="text" class="form-control form-control-sm" v-model="form.shelves[index].shelf" /> -->
                                                            <select class="form-control form-control-sm" v-model="detailForm.shelves[index].shelf_id" @change="shelfChangeDetail($event, index)">
                                                                <option v-for="shelve in shelves" :key="shelve.id" :value="shelve.id">{{ shelve.name }}</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <!-- <input type="text" class="form-control form-control-sm" v-model="form.shelves[index].layer" /> -->
                                                            <select class="form-control form-control-sm" v-model="detailForm.shelves[index].layer">
                                                                <option v-for="layer in detailForm.shelves[index].layers" :key="layer" :value="layer">Ebene {{ layer }}</option>
                                                                <!-- <option value="2">Ebene 2</option>
                                                                <option value="3">Ebene 3</option> -->
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-danger btn-xs" @click="deleteDetailShelf(index)"><i class="fas fa-fw fa-trash"></i></button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </template>
                                    </fieldset>
                                </div>
                            </div>

                            <fieldset class="p-2 mt-3 border">
                                <legend class="w-auto">Preispflege</legend>

                                <div class="row">
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="useFactor" class="control-label col-form-label col-form-label-sm">Benutze Faktor?</label>
                                                <select class="form-control form-control-sm" name="useFactor" id="useFactor" :class="{'is-invalid': detailForm.errors.has('useFactor')}" v-model="detailForm.useFactor">
                                                    <option :value="1">Ja</option>
                                                    <option :value="0">Nein</option>
                                                </select>
                                                <has-error :form="detailForm" field="useFactor"></has-error>
                                            </div>
                                        </div>
                                    </div>

                                <div class="row">
                                        <div :class="detailForm.useFactor == 1 ? 'col-md-1' : 'col-md-2'">
                                            <div class="form-group">
                                                <label for="price" class="control-label col-form-label col-form-label-sm">Preis (Brutto)*</label>
                                                <input type="number" :disabled="detailForm.useFactor == 1" class="form-control form-control-sm" name="price" id="price" :class="{'is-invalid': detailForm.errors.has('price')}" :value="calculatedDetailPrice()" @change="updateMyDetailPrice">
                                                <has-error :form="detailForm" field="price"></has-error>
                                            </div>
                                        </div>

                                        <div class="col-md-1" v-if="detailForm.useFactor == 1">
                                            <div class="form-group">
                                                <label for="factor" class="control-label col-form-label col-form-label-sm">Faktor*</label>
                                                <input type="number" class="form-control form-control-sm" name="factor" id="factor" :class="{'is-invalid': detailForm.errors.has('factor')}" v-model="detailForm.factor">
                                                <has-error :form="detailForm" field="factor"></has-error>
                                            </div>
                                        </div>

                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="pseudo_price" class="control-label col-form-label col-form-label-sm">Aktionspreis (Brutto)</label>
                                            <input type="text" class="form-control form-control-sm" name="pseudo_price" id="pseudo_price" :class="{'is-invalid': detailForm.errors.has('pseudo_price')}" v-model="detailForm.pseudo_price">
                                            <has-error :form="detailForm" field="pseudo_price"></has-error>
                                        </div>
                                    </div>

                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="purchase_price" class="control-label col-form-label col-form-label-sm">Einkaufspreis (Netto)</label>
                                            <input type="number" class="form-control form-control-sm" name="purchase_price" id="purchase_price" :class="{'is-invalid': detailForm.errors.has('purchase_price')}" v-model="detailForm.purchase_price">
                                            <has-error :form="detailForm" field="purchase_price"></has-error>
                                        </div>
                                    </div>

                                    <div class="col-md-2" v-if="notEmptyObject(article)">
                                        <div class="form-group">
                                            <label class="control-label col-form-label col-form-label-sm" :class="{'text-danger': detailForm.pseudo_price >0}">max. verfügbarer Rabatt % (Netto)</label>
                                            <input type="number" style="background-color: #D3D3D3; opacity: 0.64;" class="form-control form-control-sm" :value="calculateMargePercentDetail()" :class="{'is-invalid': detailForm.errors.has('purchase_price')}" :disabled="true"><br>
                                            <!-- <br><span :class="{'is-invalid': form.errors.has('purchase_price')}">{{ calculateMargePercent() }}</span> -->
                                            <has-error :form="detailForm" field="pseudo_price"></has-error>
                                        </div>
                                    </div>

                                    <div class="col-md-2" v-if="notEmptyObject(article)">
                                        <div class="form-group">
                                            <label class="control-label col-form-label col-form-label-sm" :class="{'text-danger': detailForm.pseudo_price >0}">Marge (absolut)</label>
                                            <input type="number" style="background-color: #D3D3D3; opacity: 0.64;"  class="form-control form-control-sm" :value="calculateMargeAbsolutDetail()" :class="{'is-invalid': detailForm.errors.has('purchase_price')}" :disabled="true">
                                            <!-- <br><span :class="{'is-invalid': form.errors.has('purchase_price')}">{{ calculateMargeAbsolut() }}</span> -->
                                            <has-error :form="detailForm" field="pseudo_price"></has-error>
                                        </div>
                                    </div>

                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="store_price" class="control-label col-form-label col-form-label-sm">Filialpreis</label>
                                            <input type="text" class="form-control form-control-sm" name="store_price" id="store_price" :class="{'is-invalid': detailForm.errors.has('store_price')}" v-model="detailForm.store_price">
                                            <has-error :form="detailForm" field="store_price"></has-error>
                                        </div>
                                    </div>

                                </div>

                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="deposit_id" class="control-label col-form-label col-form-label-sm">Pfandart*</label>
                                                <select name="deposit_id" id="deposit_id" class="form-control form-control-sm" v-model="detailForm.deposit_id" :class="{'is-invalid': detailForm.errors.has('deposit_id')}">
                                                    <option v-for="deposit in deposits" :key="deposit.id" v-bind:value="deposit.id">{{deposit.name}}</option>
                                                </select>
                                                <has-error :form="detailForm" field="deposit_id"></has-error>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="deposit" class="control-label col-form-label col-form-label-sm">Pfandsumme</label>
                                            <input type="text" class="form-control form-control-sm" name="deposit" id="deposit" :class="{'is-invalid': detailForm.errors.has('deposit')}" v-model="detailForm.deposit">
                                            <has-error :form="detailForm" field="deposit"></has-error>
                                        </div>
                                    </div>
                                    <div class="col-md-3"></div><div class="col-md-3"></div>
                                </div>

                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="article_unit_id" class="control-label col-form-label col-form-label-sm">Grundeinheit*</label>
                                                <select name="article_unit_id" id="article_unit_id" class="form-control form-control-sm" v-model="detailForm.article_unit_id" :class="{'is-invalid': detailForm.errors.has('article_unit_id')}">
                                                    <option v-for="unit in units" :key="unit.id" v-bind:value="unit.id">{{unit.description}}</option>
                                                </select>
                                                <has-error :form="detailForm" field="article_unit_id"></has-error>
                                        </div>
                                    </div>

                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="purchaseunit" class="control-label col-form-label col-form-label-sm">Verpackung</label>
                                                <input type="text" class="form-control form-control-sm" name="purchaseunit" id="purchaseunit" :class="{'is-invalid': detailForm.errors.has('purchaseunit')}" v-model="detailForm.purchaseunit">
                                                <has-error :form="detailForm" field="purchaseunit"></has-error>
                                        </div>
                                    </div>

                                    <div class="col-md-3">  
                                        <div class="form-group">
                                            <label for="referenceunit" class="control-label col-form-label col-form-label-sm">
                                                Referenz                                               
                                            </label>
                                            <input type="text" class="form-control form-control-sm" name="referenceunit" id="referenceunit" :class="{'is-invalid': detailForm.errors.has('referenceunit')}" v-model="detailForm.referenceunit">
                                            <has-error :form="detailForm" field="referenceunit"></has-error>
                                        </div>
                                    </div>
                                </div>
                                
                            </fieldset>

                            <fieldset class="p-2 mt-3 border">
                                <legend class="w-auto">Lagereigenschaften</legend>
                                <div class="row">
                                    <div class="col-md-6">

                                        <div class="form-group row">
                                            <label for="laststock" class="control-label col-sm-5 col-form-label col-form-label-sm">Aus dem Sortiment
                                                <i class="fas fa-question-circle" id="tooltip-ads"></i>
                                                <b-tooltip target="tooltip-ads" triggers="hover">
                                                    Artikel geht aus dem Sortiment &ndash; in der Front "letzte Chance". Bei Nullbestand Artikel löschen! 
                                                </b-tooltip>  
                                            </label>
                                            <div class="col-sm-6">
                                                <input type="checkbox" class="form-check-input" name="laststock" id="laststock" :class="{'is-invalid': detailForm.errors.has('laststock')}" v-model="detailForm.laststock" style="position: static; margin-left: 0;">
                                                <has-error :form="detailForm" field="laststock"></has-error>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label for="stock" class="control-label col-sm-5 col-form-label col-form-label-sm">Bestandspflege
                                                <i class="fas fa-question-circle" id="tooltip-stock"></i>
                                                <b-tooltip target="tooltip-stock" triggers="hover">
                                                    Nur bei der Nutzung der Checkbox "AdS" 
                                                </b-tooltip>  
                                            </label>
                                            <div class="col-sm-7">
                                                <input type="text" class="form-control form-control-sm" name="stock" id="stock" :class="{'is-invalid': detailForm.errors.has('stock')}" v-model="detailForm.stock">
                                                <has-error :form="detailForm" field="stock"></has-error>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label for="not_available" class="control-label col-sm-5 col-form-label col-form-label-sm">Kurzfristig nicht lieferbar
                                                <i class="fas fa-question-circle" id="tooltip-not-available"></i>
                                                <b-tooltip target="tooltip-not-available" triggers="hover">
                                                    Zur Zeit nicht lieferbar - in der Font ist der Artikel nicht mehr bestellbar (Button deaktiviert), wird aber trotzdem noch angezeigt
                                                </b-tooltip> 
                                            </label>
                                            <div class="col-sm-6">
                                                <input type="checkbox" class="form-check-input" name="not_available" id="not_available" :class="{'is-invalid': detailForm.errors.has('not_available')}" v-model="detailForm.not_available" style="position: static; margin-left: 0;">
                                                <has-error :form="detailForm" field="not_available"></has-error>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="col-md-6">

                                        <div class="form-group row">
                                            <label for="shippingtime" class="control-label col-sm-4 offset-sm-1 col-form-label col-form-label-sm">
                                                Lieferzeit (in Tagen) 
                                                <i class="fas fa-question-circle" v-b-tooltip.hover title="Nur die Anzahl der Tage eintragen (z. B. 1 oder 1-4) Voreingestellt: 1-4 Tage"></i>
                                            </label>
                                            <div class="col-sm-7">
                                                <input type="text" class="form-control form-control-sm" name="shippingtime" id="shippingtime" :class="{'is-invalid': detailForm.errors.has('shippingtime')}" v-model="detailForm.shippingtime">
                                                <has-error :form="detailForm" field="shippingtime"></has-error>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label for="publishDate" class="control-label col-sm-4 offset-md-1 col-form-label col-form-label-sm">Einstelldatum
                                                <i class="fas fa-question-circle" id="tooltip-publishDate"></i>
                                                <b-tooltip target="tooltip-publishDate" triggers="hover">
                                                    Ab diesem Tag, ist der Artikel in der Front sichtbar. Allerdings kann der Artikel erst bestellt werden, wenn der Artikel lieferbar ist ("Lieferbar ab").
                                                </b-tooltip> 
                                            </label>
                                            <div class="col-sm-7">
                                                <input type="date" class="form-control form-control-sm" name="publishDate" id="publishDate" :class="{'is-invalid': detailForm.errors.has('publishDate')}" v-model="detailForm.publishDate">
                                                <has-error :form="detailForm" field="publishDate"></has-error>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label for="releaseDate" class="control-label col-sm-4 offset-md-1 col-form-label col-form-label-sm">Lieferbar ab
                                                <i class="fas fa-question-circle" id="tooltip-releaseDate"></i>
                                                <b-tooltip target="tooltip-releaseDate" triggers="hover">
                                                    Ab diesem Tag ist der Artikel bestellbar. (Funktioniert in Kombination mit dem Einstelldatum)
                                                </b-tooltip> 
                                            </label>
                                            <div class="col-sm-7">
                                                <input type="date" class="form-control form-control-sm" name="releaseDate" id="releaseDate" :class="{'is-invalid': detailForm.errors.has('releaseDate')}" v-model="detailForm.releaseDate">
                                                <has-error :form="detailForm" field="releaseDate"></has-error>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label for="mhd" class="control-label col-sm-4 offset-sm-1 col-form-label col-form-label-sm">MHD kritisch</label>
                                            <div class="col-sm-7">
                                                <input type="date" class="form-control form-control-sm" name="mhd" id="mhd" :class="{'is-invalid': detailForm.errors.has('mhd')}" v-model="detailForm.mhd">
                                                <has-error :form="detailForm" field="mhd"></has-error>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </fieldset>


            </form>
        </b-modal>

        <b-modal id="settingsModal" v-if="existingVariations.length > 1" title="Bilder zuweisen" ok-only ok-variant="primary" ok-title="Speichern" size="sm" @ok="handleSettings($event)">
            <div class="row">
                <div class="col">
                    <label>Bitte wähle die Varianten aus, die dieses Bild erhalten soll</label>
                    <!-- <div class="form-group">
                        <label>Bitte wähle die Varianten aus, die dieses Bild erhalten soll</label>
                        <input type="checkbox" v-for="(existingVariation, index) in existingVariations" :key="existingVariation.id" :value="existingVariation.id" />
                    </div> -->
                    <div class="form-check" v-for="(existingVariation) in existingVariations" :key="existingVariation.id">
                        <input type="checkbox" class="form-check-input" :value="existingVariation.id" v-model="mappingForm.mappings">
                        <label class="form-check-label">{{ existingVariation.values[0].name  }}</label>
                    </div>
                </div>
            </div>
        </b-modal>
    </b-container>
</template>

<script>
import ClassicEditor from './../../assets/packages/ckeditor5/src/ckeditor';

// import similarArticles from './components/similarArticles';
import Advanced from './components/Advanced';
import Marketplace from './components/Marketplace';
import Sales from './components/Sales';
// import Categories from './components/Categories';
import Company from './components/Company';
import Bundle from './components/Bundle';

export default {
    name: 'articles.details',
    title: 'Artikel bearbeiten',
    components:{
        // similarArticles,
        Advanced,
        Marketplace,
        Sales,
        // Categories,
        Company,
        Bundle
    },
    data() {
        return {
            funke_mode: process.env.VUE_APP_FUNKE_MODE, 
            imageSelected: false,
            selectedImage: {},
            uploadImageForm: new window.UploadForm({
                images: [],
                article_id: "",
            }),
            groups: [],
            shelves: [],
            countries: [],
            mappingForm: new window.Form({
                mappings: {},
                uuid: ''
            }),
            form: new window.Form({
                fruit_property: null,
                id: "",
                useFactor: "",
                factor: "",
                main_detail_id: "",
                producer_id: "",
                distributor_id: "",
                name: "",
                ordernumber: "",
                categories: [],
                groups: [],
                seo_category: "",
                tax_id: "",
                han: "",
                ean: "",
                description: "",
                description_long: "",
                article_unit_id: "",
                purchaseunit: "",
                referenceunit: "",
                deposit_id: "",
                deposit: "",
                fragile:"",
                cargo:"",
                cool:"",
                maxpurchase:"",
                minpurchase:"",
                minstock:"",
                shippingtime:"",
                publishDate:"",
                releaseDate:"",
                shippingfree:"0",
                topseller:"0",
                laststock:"0",
                weight:"",
                stores: [],
                width:"",
                height:"",
                length:"",
                price: "",
                pseudo_price: "",
                purchase_price: "",
                hasVariations:0,
                active: "",
                bio: "",
                bio_controllnumber: null,
                naturland: "",
                backside: 0,
                vegan: "",
                vegetarisch: false,
                glutenfrei: "",
                laktosefrei: "",
                mhd: "",
                not_available: "",
                seasonal: "", 
                sample_picture: "", 
                store_price: "", 
                not_discountable: "", 
                stock: "", 
                bundle: 0,
                voucher_template_id: "", 
                voucher_templates: [],
                voucher: "0", 
                xxl: "", 
                demeter: "", 
                country: "", 
                article_commercial_class_id: "",
                shelves: [],
                editSlug: true,
                product: [],
                greetingcard: 0,
                fruit_property_type: null,
                potato_property: null,
                maxpurchase: null,
            }),
            imageDataForm: new window.Form({
                uuid: "",
                title: "",
                active: "0",
            }),
            detailForm: new window.Form({
                id: "",
                useFactor: "",
                factor: "",
                main_detail_id: "",
                ordernumber: "",
                han: "",
                ean: "",
                article_unit_id: "",
                purchaseunit: "",
                referenceunit: "",
                deposit_id: "",
                deposit: "",
                fragile:"",
                cargo:"",
                maxpurchase:"",
                minpurchase:"",
                minstock:"",
                shippingtime:"",
                publishDate:"",
                releaseDate:"",
                shippingfree:"0",
                topseller:"0",
                laststock:"0",
                weight:"",
                width:"",
                height:"",
                length:"",
                active: "",
                price: "",
                pseudo_price: "",
                purchase_price: "",
                not_available: "", 
                mhd: "", 
                seasonal: "",
                sample_picture: "",  
                store_price: "", 
                not_discountable: "", 
                stock: "", 
                maxpurchase: null,
                stores: [],
                voucher_template_id: "", 
                voucher_templates: [],
                voucher: "0", 
                shelves: [], 
            }),
            nutrition: new window.Form({
                'kj': "",
                'kcal': "",
                'fett': "",
                'fettsaeuren': "",
                'kohlenhydrate': "",
                'zucker': "",
                'eiweiss': "",
                'salz': "",
                'ballaststoffe': "",
                'alkohol': "",
                'nutri_score': "",
                'je': "",
                'zutaten': "",
                'inverkehrbringer': "",
                'herkunft': "",
                'bez': "",
                country_id: null,
            }),

            variationForm: new window.Form({
                'selectedVariationValues': [],
                'hiddenVariationsValues': [],
            }),
            editor: ClassicEditor,
            editorConfig: {
                simpleUpload: {
                    uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer '+ this.$auth.token()
                    }
                }
            },
            article: {},
            producers: [],
            companies: [],
            stores: [],
            taxes: [],
            units: [],
            deposits: [],
            categories: [],
            seoCategories: [],
            customerGroups: [],
            variationGroups: [],
            variationValues: [],
            voucher_templates: [],
            existingVariations: [
                // eslint-disable-next-line no-unused-vars
                values => null,
            ],
            
            sortBy: "id",
            sortDesc: false,
            perPage: 20,
            currentPage: 1,
            filter: "",
            fields: [
                {key: "id", sortable: true},
                {key: "ordernumber", label: "Artikelnummer", sortable: true},
                {key: "values.name", label: "Variante", sortable: true},
                {key: "price", label: "Preis", sortable: true},
                {key: "pseudo_price", label: "Aktionspreis", sortable: true, formatter: 'pseudoPrice'},
                {key: "active", label: "Aktiv", sortable: true},
                {key: "actions", label: "Actions", tdClass: 'hls_action_column'},
            ],
            distributors: [],
            classes: [],
            label:{
                mode: 'print',
                printer: '',
                qty: 1,
                article_id: null,
            },
            usePrintnode: process.env.VUE_APP_USE_PRINTNODE,
            printers: [],
        }
    },

    watch:{

        // form:{
        //     handler(){
        //         this.calculateMargePercent(); 
        //         this.calculateMargeAbsolut(); 
        //     },
        //     deep:true
        // }

        'article.price': function(){
            this.calculateMargePercent(); 
            this.calculateMargeAbsolut();
            this.calculateMargePer();
        },

        'existingVariations':{
            handler(){
                this.calculateMargePercentDetail(); 
                this.calculateMargeAbsolutDetail();
            },
            deep: true
        },

        'form.pseudo_price': function(){
            this.calculateMargePercent(); 
            this.calculateMargeAbsolut();
            this.calculateMargePer();
        }, 

        'form.factor': function(){
            this.calculatedPrice();
            this.calculateMargePercent(); 
            this.calculateMargeAbsolut();
            this.calculateMargePer();
        }, 

        'form.purchase_price': function(){
            this.calculateMargePercent(); 
            this.calculateMargeAbsolut();
            this.calculateMargePer();
        }, 

        'detailForm.pseudo_price': function(){
            this.calculateMargePercentDetail(); 
            this.calculateMargeAbsolutDetail();
        }, 

        'detailForm.purchase_price': function(){
            this.calculateMargePercentDetail(); 
            this.calculateMargeAbsolutDetail();
        },

        'detailForm.useFactor': function(){
            this.calculatedDetailPrice();
            this.calculateMargePercentDetail(); 
            this.calculateMargeAbsolutDetail();
        },

        'detailForm.factor': function(){
            this.calculatedDetailPrice();
            this.calculateMargePercentDetail(); 
            this.calculateMargeAbsolutDetail();
        },

        'form.useFactor': function(){
            this.calculatedPrice();
            this.calculateMargePercent(); 
            this.calculateMargeAbsolut();
            this.calculateMargePer();
        },
        'form.bio': function() {
            if(this.form.bio == 0 || this.form.bio == false)
            {
                this.form.bio_controllnumber = null;
            }
        },

        $route(to, from) {
             // react to route changes...
            if(to !== from){
                this.loadArticle();
                this.loadProducers();
                this.loadDistributors();
                this.loadTaxes();
                this.loadUnits();
                this.loadDeposits();
                this.getCompanies();
                //this.loadCustomerGroups();
                this.loadGroups();
                this.loadCategories();
                this.loadVariationGroups();
                this.loadSeoCategories();
            }
        },

    },


    methods: {
        nameWithLang ({ country, iso2, iso3 }) {
            return `${country} — [${iso2} ${iso3}]`
        },
        updateMyDetailPrice(event){
            const index = this.existingVariations.findIndex(item => item.id == this.detailForm.id);
            this.existingVariations[index].price = event.target.value;
        },

        updateMyPrice(event){
            this.article.price = event.target.value
        },

        calculatedDetailPrice(){
            const detail = this.existingVariations.find(item => item.id == this.detailForm.id);
            if(this.detailForm.useFactor == 1){
                return Number(parseFloat(this.detailForm.purchase_price * this.detailForm.factor).toFixed(2))
            }
            else{
                if(detail == undefined){
                    return 0
                }
                else{
                    return detail.price
                }   
                
            }
        },

        calculatedPrice(){
            if(this.form.useFactor == 1){
                return Number(parseFloat(this.form.purchase_price * this.form.factor).toFixed(2))
            }
            else{
                return this.article.price
            }
        },

        doLabels(bvModalEvt){
            bvModalEvt.preventDefault();

            if(this.label.mode == 'pdf')
            {
                //Die ganzen Etiketten bitte als PDF
                this.labelPdf();
            }

            if(this.label.mode == 'print')
            {
                this.labelPrint();
            }
        },

        labelPrint(){
            this.axios
            .post('/articles/details/label/print', {
                qty: this.label.qty,
                store_id: this.label.printer,
                article_id: this.label.article_id
            })
            .then(() => {
                this.$swal({
                    icon: "success",
                    title: "Druck erfolgreich!",
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    });
                this.label.qty = 1;
                // this.label.article_id = null;
                // this.$bvModal.hide('labelModal');
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
            });
        },


        labelPdf(){
            this.axios
            .post('/articles/details/label/download', {
                qty: this.label.qty,
                article_id: this.label.article_id
            },
            {
                responseType: 'arraybuffer'
            })
            .then((response) => {
                this.$swal({
                    icon: "success",
                    title: "Download erfolgreich!",
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    });
                this.label.qty = 1;
                // this.label.article_id = null;
                // this.$bvModal.hide('labelModal');
                this.downloadLabel(response)
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
            });
        },

        downloadLabel(response){
            var newBlob = new Blob([response.data], {type: 'application/pdf'})

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob)
            return
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob)
            var link = document.createElement('a')
            link.href = data
            link.download = 'Etiketten.pdf'
            link.click()
            setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data)
            }, 100)

        },


        labelModal(article){
            if(this.usePrintnode == 'false')
            {
                this.label.mode = 'pdf';
            }
            this.label.article_id = article;
            this.$bvModal.show('labelModal');
        },

        shelfChange(event, index){
            const shelf = this.shelves.find(item => item.id == this.form.shelves[index].shelf_id);
            if(shelf != undefined){
                //Wurde gefunden prüfe ob eine Änderung vorliegt
                if(shelf.layers != this.form.shelves[index].layers){
                    //Es gab eine Änderung

                    if(this.form.shelves[index].layer > shelf.layers){
                        this.form.shelves[index].layer = 1
                    }

                    //Ändere die Anzahl der Böden
                    this.form.shelves[index].layers = shelf.layers;    
                }               
            }
            else{
                this.form.shelves[index].shelf_id = this.shelves[0].id;
            }
        },

        shelfChangeDetail(event, index){
            const shelf = this.shelves.find(item => item.id == this.detailForm.shelves[index].shelf_id);
            if(shelf != undefined){
                //Wurde gefunden prüfe ob eine Änderung vorliegt
                if(shelf.layers != this.detailForm.shelves[index].layers){
                    //Es gab eine Änderung

                    if(this.detailForm.shelves[index].layer > shelf.layers){
                        this.detailForm.shelves[index].layer = 1
                    }

                    //Ändere die Anzahl der Böden
                    this.detailForm.shelves[index].layers = shelf.layers;    
                }               
            }
            else{
                this.detailForm.shelves[index].shelf_id = this.shelves[0].id;
            }
        },

        getLayersForShelf(id){
            const shelf = this.shelves.find(item => item.id == id)
            if(shelf != undefined){
                return shelf.layers
            }
            else{
                return 1;
            }
        },

        addDetailShelf(){
            this.detailForm.shelves.push({
                id: Math.random().toString(36).slice(2, 7),
                shelf: 'Neu',
                layer: 'Neu'
            })
        },

        deleteDetailShelf(index){
            this.$delete(this.detailForm.shelves, index);
        },

        addShelf(){
            this.form.shelves.push({
                shelf_id: this.shelves[0].id,
                layer: 1,
                layers: this.shelves[0].layers
            })
        },

        deleteShelf(index){
            this.$delete(this.form.shelves, index);
        },

        loadClasses() {
            this.axios
                .get("/classes")
                .then((response) => {
                    this.classes = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        handleSettings(bvModalEvt){
            bvModalEvt.preventDefault();
            //Update die Mappings
            this.mappingForm
                .post("/articles/set/mapping")
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Mapping wurde gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.loadArticle();
                    this.$bvModal.hide("settingsModal");
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        openSettingsModal(uuid){
            var media = this.article.media[uuid];

            this.mappingForm.mappings = [];
            this.mappingForm.uuid = uuid;
            if("custom_properties" in media){
                if("mapping" in media.custom_properties){
                    //Es gibt ein Mapping.
                    // this.mappingForm.mappings = media.custom_properties.mapping.split(';');
                    let mappings = media.custom_properties.mapping.split(';');
                    mappings.forEach(element => {
                        this.mappingForm.mappings.push(parseInt(element));
                    })
                }
            }


            this.$bvModal.show("settingsModal");
        },

        calculateMargePer()
        {
            var total=0; 

            var margeabsolut=((parseFloat(this.calculatedPrice()) / (parseFloat(this.article.tax.tax) + 100) * 100)) - parseFloat(this.form.purchase_price); 
            var margeabsolutpseudo=((parseFloat(this.form.pseudo_price) / (parseFloat(this.article.tax.tax) + 100) * 100)) - parseFloat(this.form.purchase_price); 
            
            // var price_netto = ((parseFloat(this.form.price) / (parseFloat(this.article.tax.tax) + 100) * 100));
            // var pseudo_price_netto = ((parseFloat(this.form.pseudo_price) / (parseFloat(this.article.tax.tax) + 100) * 100));

            // 100 / VK netto * EK netto 

            if (this.form.pseudo_price <= 0 ) {
                total = margeabsolut / this.form.purchase_price * 100;
                // total=100 / (parseFloat(price_netto) / (parseFloat(this.article.tax.tax) + 100) * 100) * margeabsolut
            }

            else {
                total = margeabsolutpseudo / this.form.purchase_price * 100;
                // total=100 / (parseFloat(this.form.pseudo_price) / (parseFloat(this.article.tax.tax) + 100) * 100) * margeabsolutpseudo
            }

            return total.toFixed(2); 
        },

        calculateMargePercent()
        {
            var total=0; 

            var margeabsolut=((parseFloat(this.calculatedPrice()) / (parseFloat(this.article.tax.tax) + 100) * 100)) - parseFloat(this.form.purchase_price); 
            var margeabsolutpseudo=((parseFloat(this.form.pseudo_price) / (parseFloat(this.article.tax.tax) + 100) * 100)) - parseFloat(this.form.purchase_price); 

            // 100 / VK netto * EK netto 

            if (this.form.pseudo_price <= 0 ) {
            total=100 / (parseFloat(this.calculatedPrice()) / (parseFloat(this.article.tax.tax) + 100) * 100) * margeabsolut
            }

            else {
            total=100 / (parseFloat(this.form.pseudo_price) / (parseFloat(this.article.tax.tax) + 100) * 100) * margeabsolutpseudo
            }

            return total.toFixed(2); 
        },

        calculateMargeAbsolut()
        {
            var total=0; 

            // 100 / VK netto * EK netto 

            if (this.form.pseudo_price <= 0 ) {
            total=((parseFloat(this.calculatedPrice()) / (parseFloat(this.article.tax.tax) + 100) * 100)) - parseFloat(this.form.purchase_price) 
            }

            else {
            total=(parseFloat(this.form.pseudo_price) / (parseFloat(this.article.tax.tax) + 100) * 100) - parseFloat(this.form.purchase_price) 
            }

            return total.toFixed(2); 
        },



        calculateMargePercentDetail()
        {
            
            var total=0; 

            var margeabsolut=((parseFloat(this.calculatedDetailPrice()) / (parseFloat(this.article.tax.tax) + 100) * 100)) - parseFloat(this.detailForm.purchase_price); 
            var margeabsolutpseudo=((parseFloat(this.detailForm.pseudo_price) / (parseFloat(this.article.tax.tax) + 100) * 100)) - parseFloat(this.detailForm.purchase_price); 

            // 100 / VK netto * EK netto 

            if (this.detailForm.pseudo_price <= 0 ) {
            
            total=100 / (parseFloat(this.calculatedDetailPrice()) / (parseFloat(this.article.tax.tax) + 100) * 100) * margeabsolut
            }

            else {
            total=100 / (parseFloat(this.detailForm.pseudo_price) / (parseFloat(this.article.tax.tax) + 100) * 100) * margeabsolutpseudo
            }

            return total.toFixed(2); 
        },

        calculateMargeAbsolutDetail()
        {
            var total=0; 

            // 100 / VK netto * EK netto 

            if (this.detailForm.pseudo_price <= 0 ) {
            total=((parseFloat(this.calculatedDetailPrice()) / (parseFloat(this.article.tax.tax) + 100) * 100)) - parseFloat(this.detailForm.purchase_price) 
            }

            else {
            total=(parseFloat(this.detailForm.pseudo_price) / (parseFloat(this.article.tax.tax) + 100) * 100) - parseFloat(this.detailForm.purchase_price) 
            }

            return total.toFixed(2); 
        },



        getVariationValue(index, group)
        {   
            // SUche in existingVariatnion(index) nach einem Eintrag 
            const result = this.existingVariations[index].values.find(value => value.group_id == group);
            
            return result.name;
        },

        saveImages(){
            this.$Progress.start();
            this.uploadImageForm.article_id = this.article.id;
            this.uploadImageForm
                .post("/articles/uploadImages")
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Bilder wurden hochgeladen",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$Progress.finish();
                    this.loadArticle();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        deleteImage(uuid)
        {
            this.$swal({
                    title: "Möchtest du das Bild wirklich löschen?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Ja, LÖSCHEN!!!`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        this.$Progress.start();
                        this.axios
                            .post("/articles/deleteImage", {uuid: uuid, article_id: this.article.id})
                            .then(() => {
                                this.$swal({
                                    icon: "success",
                                    title: "Bild gelöscht",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                });
                                this.$Progress.finish();
                                this.loadArticle();
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Da ist etwas schief gelaufen",
                                });
                                this.$Progress.fail();
                            });
                    }
                });
        },

        setDefaultImage(uuid){
            this.$Progress.start();
            this.axios
                .post("/articles/setDefaultImage", {uuid: uuid, article_id: this.article.id})
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Bildertitel gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$Progress.finish();
                    this.loadArticle();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },
        setDefaultHoverImage(uuid, shouldDelete){
            this.$Progress.start();
            this.axios
                .post("/articles/setDefaultHoverImage", {uuid: uuid, article_id: this.article.id, delete: shouldDelete})
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Bildertitel gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$Progress.finish();
                    this.loadArticle();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        saveImageData(){
            this.$Progress.start();
            this.imageDataForm
                .post("/articles/changeTitle")
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Bildertitel gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$Progress.finish();
                    this.loadArticle();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        selectImage(index)
        {
            //console.log(index);
            this.imageSelected = (index == this.imageSelected ? '' : index);
            this.selectedImage = this.article.media[index];
            this.imageDataForm.uuid = this.article.media[index].uuid;
            this.imageDataForm.title = this.article.media[index].custom_properties.title;

            if(this.article.media[index].custom_properties.active == 'true')
            {
                this.imageDataForm.active = 1;
            }
            else
            {
                this.imageDataForm.active = 0;
            }
            
        },

        toggleHasVariation()
        {
            if(this.existingVariations.length >= 2)
            {
                this.form.hasVariations = true;
                this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: "Um Varianten abzuschalten, müssen alle Varianten bis auf die Basisvariante gelöscht werden.",
                            });
                
            }
        },

        deleteVariationValue(id)
        {
            this.$swal({
                title: "Möchtest du die Artikelvariante wirklich löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    this.form
                        .delete("/articles/deleteVariation/" + id)
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Artikelvariante erfolgreich gelöscht!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            this.loadArticle();
                        })
                        .catch((error) => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: error.response.data.error,
                            });
                        });
                }
            });
        },

        cloneArticle()
        {
            this.$swal({
                title: "Möchtest du den Artikel wirklich duplizieren?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, duplizieren!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    this.axios
                        .post("/articles/clone", {
                            'article_id': this.article.id,
                        })
                        .then((res) => {
                            this.$swal({
                                icon: "success",
                                title: "Artikel wurde dupliziert",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                            });
                            this.$router.push({name: 'articles.details', params: {id: res.data.data.id}});
                        })
                        .catch((error) => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: error.response.data.error,
                            });
                        });
                }
            });
        },

        editVariation(id){
            this.$Progress.start();
            this.detailForm.price = this.calculatedDetailPrice();
            this.detailForm
                .put("/articles/details/"  + id )
                .then(() => {
                    this.$bvModal.hide("editModal");
                    this.$swal({
                        icon: "success",
                        title: "Artikelvarianten wurden erstellt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$Progress.finish();
                    this.loadArticle();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        createVariations(){
            this.$Progress.start();
            this.variationForm
                .post("/articles/" + this.$route.params.id + '/createVariations')
                .then(() => {
                    this.$bvModal.hide("variationModal");
                    this.$swal({
                        icon: "success",
                        title: "Artikelvarianten wurden erstellt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$Progress.finish();
                    this.loadArticle();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        updateValues(event){
            this.loadVariationValues(event.target.value);
        },

        createModal() {
            if(this.article.variation_groups.length > 0)
            {
                this.variationGroups = [];
                this.variationGroups.push(this.article.variation_groups[0]);
            }
            this.loadVariationValues(this.variationGroups[0].id);
            this.variationForm.selectedVariationValues = [];
            this.$bvModal.show("variationModal");
            
        },

        editModal(article) {
            this.$bvModal.show("editModal");
            this.detailForm.fill(article);
        },

        handleDetailOk(bvModalEvt, id){
            bvModalEvt.preventDefault();
            this.handleDetailSubmit(id);
        },

        handleDetailSubmit(id){
            this.editVariation(id);
        },

        handleOk(bvModalEvt){
            bvModalEvt.preventDefault();
            this.handleSubmit();
        },

        handleSubmit(){
            this.createVariations();
        },

        pseudoPrice(value)
        {
            if(value == null)
            {
                return "Nicht definiert";
            }

            return value;
        },

        saveNutrition()
        {
            this.$Progress.start();
            this.nutrition
                .post("/articles/" + this.$route.params.id + '/updateNutrition')
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Artikel Nährwerte wurden gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$Progress.finish();
                    this.loadArticle();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        saveArticle(){
            this.$Progress.start();
            //this.form.test = this.test;
            this.form.price = this.calculatedPrice()
            this.form
                .put("/articles/" + this.$route.params.id)
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Artikel erfolgreich geändert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$Progress.finish();
                    this.loadArticle();
                    //this.$router.push('/articles');
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadArticle() {
            this.$Progress.start();
            this.axios
                .get("/articles/" + this.$route.params.id)
                .then((response) => {
                    this.article = response.data.data;
                    if(this.article.description_long == null)
                    {
                        this.article.description_long = '';
                    }
                    this.form.fill(this.article);
                    this.form.editSlug = false;
                    if(this.article.nutrition != null)
                    {
                        this.nutrition.fill(this.article.nutrition);
                    }
                    this.existingVariations = this.article.variations;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen1",
                    });
                    this.$Progress.fail();
                });
        },

        loadCategories() {
            this.$Progress.start();
            this.axios
                .get("/categoriesArticleList")
                .then((response) => {
                    this.categories = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadGroups() {
            this.$Progress.start();
            this.axios
                .get("/groupsArticleList")
                .then((response) => {
                    this.groups = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadSeoCategories() {
            this.$Progress.start();
            this.axios
                .get("/seocategories")
                .then((response) => {
                    this.seoCategories = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadStores() {
            this.axios
                .get("/stores")
                .then((response) => {
                    this.stores = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        loadCustomerGroups() {
            this.$Progress.start();
            this.axios
                .get("/customer-groups")
                .then((response) => {
                    this.customerGroups = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },


        loadDeposits() {
            this.$Progress.start();
            this.axios
                .get("/deposits")
                .then((response) => {
                    this.deposits = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadUnits() {
            this.$Progress.start();
            this.axios
                .get("/units")
                .then((response) => {
                    this.units = response.data.data;

                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadDistributors() {
            this.$Progress.start();
            this.axios
                .get("/distributors", { 
                    params: {
                        per_page: 150
                    }
                })
                .then((response) => {
                    this.distributors= response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadProducers() {
            this.$Progress.start();
            this.axios
                .get("/producers", {
                    params: {
                        per_page: 1000,
                    }
                })
                .then((response) => {
                    this.producers = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadTaxes() {
            this.$Progress.start();
            this.axios
                .get("/taxes")
                .then((response) => {
                    this.taxes = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadVariationGroups() {
            this.$Progress.start();
            this.axios
                .get("/variations")
                .then((response) => {
                    this.variationGroups = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadVariationValues(id) {
            this.$Progress.start();
            this.axios
                .get("/variations/" + id + "/values")
                .then((response) => {
                    this.variationValues = response.data.data;
                    this.variationForm.selectedVariationValues = [];
                    this.variationForm.hiddenVariationsValues = [];
                    
                    this.existingVariations.forEach(element => {
                        element.values.forEach(variation => {
                            console.log(variation.id);
                            this.variationForm.selectedVariationValues.push(variation.variation_value_id);
                        })
                    })
                    this.existingVariations.forEach(element => {
                        element.values.forEach(variation => {
                            console.log(variation.id);
                            this.variationForm.hiddenVariationsValues.push(variation.variation_value_id);
                        })
                    })

                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadVoucherTemplates() {
            this.axios
                .get("/voucher_templates")
                .then((response) => {
                    this.voucher_templates = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        getCompanies(){
            this.axios
                .get("/companies")
                .then((response) => {
                    this.companies = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        getShelves(){
            this.axios
                .get("/shelves", {
                    params: {
                        per_page: 175
                    }
                })
                .then((response) => {
                    this.shelves = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },
        getPrinters() {
            this.axios
                .get("/articles/label/printers")
                .then((response) => {
                    //console.log(response.data);
                    this.printers = response.data;
                    
                    if(Object.keys(response.data).length > 0)
                    {
                        this.label.printer = response.data[0].id;
                    }
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },
        getCountries() {
            this.axios
                .get('/countries')
                .then((response) => {
                    this.countries = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    })
                })
        }
    },

    created() {
        this.loadArticle();
        this.loadProducers();
        this.loadDistributors();
        this.loadTaxes();
        this.loadUnits();
        this.loadDeposits();
        this.getCompanies();
        //this.loadCustomerGroups();
        this.loadGroups();
        this.loadCategories();
        this.loadVariationGroups();
        this.loadSeoCategories();
        this.loadStores();
        this.loadVoucherTemplates(); 
        this.loadClasses(); 
        this.getShelves();
        this.getPrinters();
        this.getCountries();
    },

    computed: {
        tableData() {
            return this.existingVariations || [];
        },

        rows() {
            return this.existingVariations.length;
        },
    },
    


}
</script>

<style>
.overlay {
            position: absolute;
            color: #fff;
            font-size: 2em;
            font-weight: bold;
            display: block;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            opacity: 0.4;
            transition: .5s ease;
            border-radius: 0 !important;
            background-color:red !important;
        }
</style>
